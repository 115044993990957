define("frontend/mixins/holiday-names-from-dates", ["exports", "frontend/mixins/dates-to-ranges", "lodash/forEach"], function (_exports, _datesToRanges, _forEach2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_datesToRanges.default, {
    holidayDatesService: Ember.inject.service(),
    createHolidayNameMapping: function createHolidayNameMapping(country, stateOrProvince) {
      var holidayNameMapping = {};
      this.get('holidayDatesService').getHolidays(country, stateOrProvince).forEach(function (holiday) {
        (0, _forEach2.default)(holiday.by_year, function (year) {
          var middleDate = moment(year.date);
          holidayNameMapping[middleDate.format('YYYY-MM-DD')] = holiday.holiday_name;

          for (var i = 1; i <= 3; i++) {
            holidayNameMapping[middleDate.clone().add(i, 'day').format('YYYY-MM-DD')] = holiday.holiday_name;
            holidayNameMapping[middleDate.clone().subtract(i, 'day').format('YYYY-MM-DD')] = holiday.holiday_name;
          }
        });
      });
      return holidayNameMapping;
    },
    holidayNamesFromDates: function holidayNamesFromDates(dateArray, country, stateOrProvince) {
      var holidayNameMapping = this.createHolidayNameMapping(country, stateOrProvince);
      var sortedDates = dateArray.sort().map(function (date) {
        return moment(date);
      });
      var holidayNames = sortedDates.map(function (date) {
        return holidayNameMapping[date.format('YYYY-MM-DD')];
      });
      return holidayNames.uniq();
    },
    holidayDatesFromSelectedDates: function holidayDatesFromSelectedDates(dateArray, holidayPolicy, country, stateOrProvince) {
      var _this = this;

      var holidayNameMapping = this.createHolidayNameMapping(country, stateOrProvince);
      var holidayDateMapping = {};
      holidayPolicy.get('dates').forEach(function (date) {
        var holiday = holidayNameMapping[date];

        if (!holidayDateMapping[holiday]) {
          holidayDateMapping[holiday] = [];
        }

        holidayDateMapping[holiday].push(date);
      });
      var holidayNames = this.holidayNamesFromDates(dateArray, country, stateOrProvince);
      return holidayNames.map(function (holidayName) {
        return _this.datesToRanges(holidayDateMapping[holidayName], 'dddd, MMMM D', 'through');
      });
    }
  });

  _exports.default = _default;
});