define("frontend/controllers/add-client/billing-settings", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    session: inject.service(),
    accountSession: inject.service(),
    addClientSaving: inject.service(),
    flashMessage: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    paymentMethodAdded: false,
    companyBillingSettings: computed('isPetParent', 'model', function () {
      var _this = this;

      if (this.get('isPetParent')) {
        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/users/get_client_companies"),
          method: 'GET'
        }).then(function (response) {
          if (response.settings.length == 1) {
            _this.set('companyBillingSettings', response.settings.get('firstObject'));
          } else {
            _this.transitionToRoute('portal.billing');
          }
        });
      }
    }),
    rightButtonText: computed('isPetParent', function () {
      if (this.get('isPetParent')) {
        return 'finish';
      } else {
        return 'next';
      }
    }),
    actions: {
      goNext: function goNext(user) {
        var _this2 = this;

        var companyRequiresCardOnFile = this.get('companyBillingSettings.requires_card_on_file');
        var paymentMethodOnFile = this.get('paymentMethodAdded');

        if (companyRequiresCardOnFile && !paymentMethodOnFile) {
          this.get('flashMessage').error('A payment method is required to finish setting up your account.', 'error');
          return;
        }

        this.set('saving', true);
        this.get('addClientSaving').saveUser(user).then(function (savedUser) {
          if (_this2.get('isPetParent')) {
            return _this2.transitionToRoute('portal.profile', {
              queryParams: {
                addedClient: _this2.get('companyBillingSettings.accepted')
              }
            });
          } else {
            return _this2.transitionToRoute('add-client.documents', savedUser.get('household.id'));
          }
        }).finally(function () {
          _this2.set('saving', false);
        });
      },
      paymentMethodAdded: function paymentMethodAdded() {
        this.set('paymentMethodAdded', true);
      }
    }
  });

  _exports.default = _default;
});