define("frontend/templates/add-client/billing-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XwrZie5Z",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"header-text-wormhole-container\"]],{\"statements\":[[0,\"  Set up billing\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"billing-settings-page\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isPetParent\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"assets/images/piggy-bank@3x.png\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"companyBillingSettings\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"client-billing-inputs\",null,[[\"settings\",\"household\",\"paymentMethodAdded\"],[[24,[\"companyBillingSettings\"]],[24,[\"model\",\"householdUser\",\"household\"]],[28,\"action\",[[23,0,[]],\"paymentMethodAdded\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"loading-spinner\",null,[[\"message\"],[\"Loading...\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"billing-inputs\",null,[[\"householdUser\",\"allInvoiceFrequencies\",\"settings\"],[[24,[\"model\",\"householdUser\"]],[24,[\"model\",\"invoiceFrequencies\"]],[24,[\"companyBillingSettings\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"unless\",[[24,[\"anySidePanelActive\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"adaptive-navigation-buttons\",null,[[\"rightText\",\"clickRight\",\"clickLeft\"],[[24,[\"rightButtonText\"]],[28,\"action\",[[23,0,[]],\"goNext\",[24,[\"model\",\"householdUser\"]]],null],[28,\"route-action\",[\"goBack\",[24,[\"model\",\"householdUser\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/add-client/billing-settings.hbs"
    }
  });

  _exports.default = _default;
});