define("frontend/helpers/hints-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hintsHelper = hintsHelper;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var hints = {
    gettingPaid: {
      creditCards: function creditCards(company) {
        var paymentType = 'card';

        if (company.get('country') === 'United States') {
          paymentType += ' or ACH';
        }

        return "<p>PetPocketbook uses Stripe, the same company that OpenTable, Lyft, and Amazon relies on for payment processing. We update your invoice records in real time when your clients pay by ".concat(paymentType, " through our platform, which means no more bookkeeping! You can also manually record in PetPocketbook any payments collected outside of our system.</p>");
      },
      bankAccountNumber: 'This is the account where we’ll deposit payments that your clients make on PetPocketbook.',
      paypalMe: '<p>This is optional. If you want to accept PayPal payments, we can include a link to your PayPal.Me page on your clients’ invoices.</p><p>Please note that you will still have to manually record payments received on PetPocketbook using this option.</p>',
      venmo: '<p>This is optional. If you want to accept Venmo payments, we can include a link to your Venmo page on your clients’ invoices.</p><p>Please note that you will still have to manually record payments received on PetPocketbook using this option.</p>',
      autoBill: '<p>How often do you want your clients to be billed? This sets the default for any new clients. Don’t worry, you will still be able to change the billing frequency for each client!</p><p>Select:</p><p>“Daily” to send bills at the end of each day that appointments have been completed;</p><p>“Weekly” to send bills every Monday, covering appointments from the previous Monday through Sunday;</p><p>“Every other week” to send bills every other Monday;</p><p>“1st and 15th each month” to send bills on the 1st and 15th of each month; or</p><p>“Monthly” to send bills on the 1st of each month.</p>',
      autoPay: 'If your clients are on AutoPay, their payment method will be charged automatically at the end of each billing period, and they will receive an itemized receipt for their charges.',
      autoPayDisabled: 'If your clients are on AutoPay, their payment method will be charged automatically at the end of each billing period, and they will receive an itemized receipt for their charges. This setting cannot be changed because you are on our Free Tier.',
      cardRequirement: 'If your clients are required to have a card on file, clients must add a card when setting up their accounts and cannot book appointments until then. This is always enforced if AutoPay is required.',
      billingPeriod: '<p>When you select every other week, we need to know which billing period your company is on.</p>',
      invoiceNote: '<p>This is optional and will appear at the bottom of the invoice emails sent to your clients. For example, you can list other payment methods you accept that are not already listed above.</p>',
      salesTax: '<p>Sales tax rates and laws vary from state to state. To collect sales tax, your state may require you to obtain a sales tax permit. Please check your state and/or city websites for more information on tax laws in your area.</p>',
      serviceFee: '<p>Service fees allow you to accept credit cards without losing money to processing fees. When enabled, your clients will pay a service fee when checking out online on PetPocketbook. This fee will cover any processing fees, dispute fees, refund fees, you name it. If you’d like, you can still give them options to pay outside of PetPocketbook (e.g., cash or check) if they want to avoid this charge.</p>',
      taxId: function taxId(company) {
        var country = company.get('country');

        if (country === 'United States') {
          return '<p>Your tax ID is required to verify your bank account, so we can send you money when your clients pay with PetPocketbook. If you do not have an EIN, please enter your SSN in this field</p>';
        } else if (country === 'Australia') {
          return '<p>Your tax ID is required to verify your bank account, so we can send you money when your clients pay with PetPocketbook. Your tax ID should be a 9-digit ACN or 11-digit ABN. If you don\'t have an ACN or ABN, please enter your 9-digit TFN in this field.</p>';
        } else {
          return '<p>Your tax ID is required to verify your bank account, so we can send you money when your clients pay with PetPocketbook. If you do not have a Business Number, please enter your SIN in this field.</p>';
        }
      },
      dobAndSsn: '<p>This is required to verify the identity of the account owner.</p>',
      authorizedIndividual: '<p>Your bank account must be tied to an individual to ensure that we are sending money to the correct individual.</p>',
      billReminders: '<p>Want to get paid faster? We can send friendly automated bill reminders to clients with overdue balances, so that you don’t have to have these awkies interactions. Don’t worry, we will never send a bill reminder on the same day as a bill or immediately after a bill because new bills will include previous balances.</p>'
    },
    payingStaff: {
      payPeriod: '<p>This helps us sync all your staff on the same two-week pay cycle.</p>',
      payFrequency: '<p>How often will you be paying this staff member? This helps us determine the correct pay periods for your staff.</p>'
    },
    addPet: {
      firstName: '<p>If pet name is long, please consider inputting a shorter, recognizable nickname, if you have one.</p>',
      internalNotes: '<p>This will be visible only to you and your staff!</p>',
      birthday: "<p>Don't worry if you don't know this pet's exact birthday! Just put down the date that the family celebrates (e.g. adoption date) along with your best guess for birth year so that we can help you keep track of approximate age.</p>"
    },
    editAppointment: {
      specialInstructions: '<p>Is there something non-routine about this appointment? Add those instructions here!</p>'
    },
    clients: {
      autoPay: 'If your client is on auto pay, their card will be charged automatically at the end of each billing period, and they will receive an itemized receipt for their charges.'
    },
    staff: {
      firstName: 'Enter full first name. Your staff will have the opportunity to enter a preferred name during his / her self-onboarding process.',
      userRole: '<p>This selection determines what this staff member has access to within PetPocketbook.</p><p>An “administrator” has access to everything.</p><p>“Staff” users can access only client information and their own schedules. They cannot make changes to their schedules or any company settings.</p>',
      startDate: 'The start date you input helps us determine the first pay period for this staff member. We will be emailing login instructions to your staff as soon as you save this form.',
      compensationType: '<p>By sharing compensation information with us, we can automatically calculate how much you owe your staff each pay period.</p><p>This is an optional field. However, if you do make a selection, you will be required to complete any additional compensation fields that may appear before submitting this form.</p>',
      differByServicePercent: '<p>If you plan to pay your staff the same commission rate across all types of services, select “No”.</p><p>If you select “Yes”, you will be able to set a different commission rate for each service.</p>',
      differByServiceDollar: '<p>If you plan to pay your staff the same dollar amount across all types of services, select “No”.</p><p>If you select "Yes", you will be able to set a different dollar amount for each service.</p>',
      perCommissionAmount: 'We will calculate your staff’s compensation by multiplying your indicated commission rate(s) against what your clients owe you for the services that are marked complete on your staff’s schedule in PetPocketbook.',
      amount: function amount(compensationType, payFrequency) {
        if (compensationType === 'per_commission') {
          return 'We will calculate your staff’s compensation by multiplying your indicated commission rate(s) against what your clients owe you for the services that are marked complete on your staff’s schedule in PetPocketbook.';
        } else if (compensationType === 'salary') {
          if (payFrequency === 'bi_weekly') {
            return 'How much will you pay this staff member every other week?';
          } else if (payFrequency === 'weekly') {
            return 'How much will you pay this staff member each week?';
          } else if (payFrequency === 'semi_monthly') {
            return 'How much will you pay this staff member on the 1st and 15th of each month?';
          }
        } else {
          return 'We will calculate your staff’s compensation by adding what you input in this “Amount to pay” field for each appointment that is marked complete on your staff’s schedule in PetPocketbook.';
        }
      },
      addMinAmount: function addMinAmount(payFrequency) {
        if (payFrequency === 'bi_weekly') {
          return 'Select "Yes" if you’d like to guarantee your staff a minimum amount of pay every other week.';
        } else if (payFrequency === 'weekly') {
          return 'Select "Yes" if you’d like to guarantee your staff a minimum amount of pay each week.';
        } else if (payFrequency === 'semi_monthly') {
          return 'Select "Yes" if you’d like to guarantee your staff a minimum amount of pay on the 1st and 15th of each month.';
        }
      },
      minAmount: function minAmount(payFrequency) {
        if (payFrequency === 'bi_weekly') {
          return 'Enter minimum amount to pay every other week.';
        } else if (payFrequency === 'weekly') {
          return 'Enter minimum amount to pay every week.';
        } else if (payFrequency === 'semi_monthly') {
          return 'Enter minimum amount to pay on the 1st and 15th of each month.';
        }
      }
    }
  };

  function isFunction(functionToCheck) {
    var getType = {};
    return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
  }

  function hintsHelper(_ref) {
    var _ref2 = _toArray(_ref),
        args = _ref2.slice(0);

    var hintPath = args.shift();

    var _hintPath$split = hintPath.split('.'),
        _hintPath$split2 = _slicedToArray(_hintPath$split, 2),
        hintSection = _hintPath$split2[0],
        hintInput = _hintPath$split2[1];

    var hint = hints[hintSection][hintInput];

    if (isFunction(hint)) {
      return hint.apply(this, args);
    } else {
      return hint;
    }
  }

  var _default = Ember.Helper.helper(hintsHelper);

  _exports.default = _default;
});