define("frontend/templates/components/add-client/add-pet-prompt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uhStKfkq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[10,\"src\",\"assets/images/pet-placeholder@3x.png\"],[10,\"class\",\"avatar-image\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"Would you like to add another pet?\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"clarification\"],[8],[0,\"If there are additional pets in your household that you’d like your pet care professionals to know about, you can add them here!\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"button-container\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"tabIndex\",\"onclick\",\"color\"],[\"1\",[28,\"action\",[[23,0,[]],\"skip\"],null],\"red-white-border\"]],{\"statements\":[[0,\"    skip\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"tabIndex\",\"onclick\",\"color\"],[\"2\",[28,\"action\",[[23,0,[]],\"yes\"],null],\"red\"]],{\"statements\":[[0,\"    yes\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/add-client/add-pet-prompt.hbs"
    }
  });

  _exports.default = _default;
});