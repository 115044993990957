define("frontend/components/grouped-appointment-request-list-item", ["exports", "frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['grouped-appointment-list-item'],
    classNameBindings: ['swipedLeft', 'hasHoverButtons:has-hover-buttons'],
    appointmentRequest: null,
    media: inject.service(),
    currentUser: inject.service(),
    isPetParent: computed.alias('currentUser.user.isPetParent'),
    cannotModify: computed('isPetParent', 'appointmentRequest', function () {
      var isAllDayService = this.get('appointmentRequest.offered_service.all_day_service');
      var isPetParent = this.get('isPetParent');
      return isAllDayService && isPetParent;
    }),
    isExpired: computed('appointmentRequest.starts_at', 'appointmentRequest.starts_at_time', function () {
      var now = moment().tz(this.get('appointmentRequest.time_zone'));

      if (this.get('appointmentRequest.offered_service.all_day_service')) {
        return now > moment(this.get('appointmentRequest.starts_at')).add(1, 'day');
      }

      var day = moment(this.get('appointmentRequest.starts_at')).format('YYYY-MM-DD');
      var pickupWindow = this.get('appointmentRequest.pickupWindow');

      if (pickupWindow) {
        // eslint-disable-next-line no-unused-vars
        var _pickupWindow$split = pickupWindow.split(' - '),
            _pickupWindow$split2 = _slicedToArray(_pickupWindow$split, 2),
            startTime = _pickupWindow$split2[0],
            endTime = _pickupWindow$split2[1];

        return now > moment("".concat(day, " ").concat(startTime), 'YYYY-MM-DD h:mmA');
      } else {
        var time = this.get('appointmentRequest.starts_at_time');
        return now > moment("".concat(day, " ").concat(time), 'YYYY-MM-DD h:mm A');
      }
    }),
    hasHoverButtons: computed.not('isExpired'),
    companyLogoOrPlaceholder: computed('appointmentRequest.company_logo', function () {
      if (this.get('appointmentRequest.company_logo')) {
        return this.get('appointmentRequest.company_logo');
      } else {
        return 'assets/images/company-image.svg';
      }
    }),
    addOnServices: computed('appointmentRequest.add_on_services.[]', function () {
      var appts = this.get('appointmentRequest.add_on_services');
      var addOnServices = [];
      appts.forEach(function (appt) {
        addOnServices.pushObject(appt);
      });
      return addOnServices;
    }),
    addOnServicesTooltipText: computed('appointmentRequest.add_on_services.@each.offered_service.name', function () {
      var addOns = this.get('appointmentRequest.add_on_services');
      var text = '<div class="tooltip-header">add-ons:</div>';
      addOns.forEach(function (addOn) {
        text += "<div class=\"tooltip-add-on-service\">+ ".concat(addOn.offered_service.name, " (").concat(addOn.offered_service.duration_display, ")");

        if (!addOn.includes_all_pets) {
          text += " for <span class=\"bold\">".concat(_stringUtil.default.joinWithAnd(addOn.pets.mapBy('name')), "</span>");
        }

        text += '</div>';
      });
      return text;
    }),
    actions: {
      cancelAppointmentRequest: function cancelAppointmentRequest() {
        this.get('cancelAppointmentRequest')(this.get('appointmentRequest'));
      },
      modifyAppointmentRequest: function modifyAppointmentRequest() {
        if (this.get('cannotModify')) return;
        this.get('modifyAppointmentRequest')(this.get('appointmentRequest'));
      },
      mobileClickAppointment: function mobileClickAppointment() {
        if (this.get('media.isMobile') && this.get('hasHoverButtons')) {
          this.toggleProperty('swipedLeft');
        }
      }
    }
  });

  _exports.default = _default;
});