define("frontend/services/beacon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    setupBeacons: function setupBeacons() {
      var user = this.get('currentUser.user');

      if (!user) {
        // eslint-disable-next-line no-undef,new-cap
        Beacon('init', '2751f514-5f7a-4d76-9823-06d0d9e4dabe');
      } else {
        var identifyParams = {
          name: user.get('fullName'),
          email: user.get('email'),
          'company-charging-service-fees': user.get('company_charges_service_fees'),
          'company-has-an-all-day-overnight': user.get('company_has_an_all_day_overnight')
        };

        if (user.get('isPetParent')) {
          // eslint-disable-next-line no-undef,new-cap
          Beacon('init', '61fef2fa-4ccd-4e90-9259-c3b0b8b1634e');
          identifyParams.companies = user.get('client_accounts').map(function (a) {
            return a.company_name;
          }).join(', ');
        } else {
          if (user.get('isStaff')) {
            // eslint-disable-next-line no-undef,new-cap
            Beacon('init', '82573644-6551-4586-8818-3f59f255f923');
          } else if (user.get('isSeniorStaff')) {
            // eslint-disable-next-line no-undef,new-cap
            Beacon('init', '3441d334-fc39-416e-a4f2-4d41b888acfe');
          } else if (user.get('isManager')) {
            // eslint-disable-next-line no-undef,new-cap
            Beacon('init', '9ef30f48-79d1-49ca-8fc5-1b2ebc2c3bcd');
          } else if (user.get('isOwner')) {
            // eslint-disable-next-line no-undef,new-cap
            Beacon('init', '1ae369ea-1386-4eef-89c7-41ded11dd3fd');
          } else {
            // eslint-disable-next-line no-undef,new-cap
            Beacon('init', '2751f514-5f7a-4d76-9823-06d0d9e4dabe');
          }

          identifyParams.company_name = user.get('company_name');
        } // eslint-disable-next-line no-undef,new-cap


        Beacon('identify', identifyParams);
      }
    },
    openSupportBeacon: function openSupportBeacon() {
      // eslint-disable-next-line no-undef,new-cap
      Beacon('suggest'); // eslint-disable-next-line no-undef,new-cap

      Beacon('open');
    },
    recordPageView: function recordPageView() {
      // eslint-disable-next-line no-undef,new-cap
      Beacon('event', {
        type: 'page-viewed',
        url: window.location.href,
        title: window.location.pathname
      });
    }
  });

  _exports.default = _default;
});