define("frontend/components/schedule/index/service-item", ["exports", "frontend/config/environment", "frontend/utils/date-util", "moment", "jquery"], function (_exports, _environment, _dateUtil, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var inFuture = _dateUtil.default.inFuture;

  var _default = Ember.Component.extend({
    classNames: ['service-item', 'col-xs-12'],
    classNameBindings: ['isComplete:complete', 'highlighted'],
    service: null,
    showChangeCareProvider: false,
    scheduleDragActionService: inject.service(),
    store: inject.service(),
    timeZone: inject.service(),
    checkDuplicateAppointmentTimes: inject.service(),
    checkStartedAppointment: inject.service(),
    draggedOver: false,
    showCanceledAppointments: false,
    currentUser: inject.service(),
    flashMessage: inject.service(),
    media: inject.service(),
    highlightWindowStart: null,
    highlightWindowEnd: null,
    packReportCards: [],
    showStartedAppointmentMessage: false,
    allDayRequestInTimeSlotModalMessage: "Anytime you drag-and-drop to approve a pending request for an all-day service, we'll automatically put the appointment in the \u201Call-day\u201D row of your schedule.",
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.disableRightClickForMobile();
    },
    disableRightClickForMobile: function disableRightClickForMobile() {
      if (this.isMobile()) {
        (0, _jquery.default)(this.element).on('contextmenu', function () {
          return false;
        });
        (0, _jquery.default)(this.element.querySelectorAll('.pet-item')).on('contextmenu', function () {
          return false;
        });
      }
    },
    isMobile: function isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    isAndroid: function isAndroid() {
      return /Android/i.test(navigator.userAgent);
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).off('contextmenu');
      (0, _jquery.default)(this.element.querySelectorAll('.pet-item')).off('contextmenu');
    },
    highlighted: computed('highlightWindowStart', 'highlightWindowEnd', function () {
      var startTime = this.get('highlightWindowStart');
      var endTime = this.get('highlightWindowEnd');

      if (!startTime || !endTime || this.get('time') == 'all-day') {
        return false;
      }

      var time = (0, _moment.default)("".concat(this.get('time')).concat(this.get('ampm')), 'h:mmA');
      startTime = (0, _moment.default)(startTime, 'h:mmA');
      endTime = (0, _moment.default)(endTime, 'h:mmA');
      return time.isSameOrAfter(startTime) && time.isBefore(endTime);
    }),
    sortedGroupedAppointments: computed('service.sortedGroupedAppointmentsWithoutCanceled.[]', 'service.sortedCanceledGroupedAppointments.[]', function () {
      if (this.get('showCanceledAppointments')) {
        return this.get('service.sortedCanceledGroupedAppointments');
      } else {
        return this.get('service.sortedGroupedAppointmentsWithoutCanceled');
      }
    }),
    careProviderId: computed('service.care_provider.id', function () {
      return this.get('service.care_provider.id');
    }),
    time: computed('service.starts_at', 'service.all_day_service', function () {
      if (this.get('service.all_day_service')) {
        return 'all-day';
      }

      var startsAt = this.get('service.starts_at');
      return _moment.default.parseZone(startsAt).format('h:mm');
    }),
    ampm: computed('service.starts_at', 'service.all_day_service', function () {
      if (this.get('service.all_day_service')) {
        return;
      }

      var startsAt = this.get('service.starts_at');
      return _moment.default.parseZone(startsAt).format('A');
    }),
    isComplete: computed('service.sortedGroupedAppointmentsWithoutCanceled.@each.completed', 'showCanceledAppointments', function () {
      if (this.get('showCanceledAppointments')) {
        return false;
      }

      return this.get('service.sortedGroupedAppointmentsWithoutCanceled').isEvery('completed', true);
    }),
    showScheduleTrash: function showScheduleTrash() {
      this.set('scheduleDragActionService.showingCancel', true);
      this.set('scheduleDragActionService.showingArchive', true);
    },
    hideScheduleTrash: function hideScheduleTrash() {
      this.set('scheduleDragActionService.showingCancel', false);
      this.set('scheduleDragActionService.showingArchive', false);
    },
    confirmRequest: function confirmRequest(appointmentRequest, position) {
      var service = this.get('service');

      var startsAtString = _moment.default.parseZone(service.get('starts_at')).format('h:mm A');

      var startTime = (0, _moment.default)(startsAtString, 'h:mm A');
      var timeFrameStart = (0, _moment.default)(appointmentRequest.get('time_frame_start_time'), 'h:mmA');
      var timeFrameEnd = (0, _moment.default)(appointmentRequest.get('time_frame_end_time'), 'h:mmA');
      var serviceDate = (0, _moment.default)(service.get('starts_at')).startOf('day');
      var startsAtDate = (0, _moment.default)(appointmentRequest.get('starts_at')).startOf('day');

      if (!service.get('all_day_service') && appointmentRequest.get('offered_service.all_day_service')) {
        this.set('showAllDayRequestInTimeSlotModal', true);
      }

      if (startTime.isBefore(timeFrameStart) || startTime.isSameOrAfter(timeFrameEnd) || !serviceDate.isSame(startsAtDate)) {
        appointmentRequest.set('movingInFlight', false);
        this.set('appointmentRequestBeingConfirmed', appointmentRequest);
        this.set('selectedPosition', position);

        if (!serviceDate.isSame(startsAtDate)) {
          this.set('movedToWrongDate', true);
        }

        this.set('selectedStartTime', startsAtString);
        this.set('showOutOfTimeWindowModal', true);
        return;
      }

      this.handleConfirmingRequest(appointmentRequest, position);
    },
    handleConfirmingRequest: function handleConfirmingRequest(appointmentRequest, position) {
      var _this = this;

      if (this.get('currentUser.user').shouldSeeMessage('drag-drop-appointment-request')) {
        this.set('showDragDropRequestMessage', true);
      }

      var care_provider_id = this.get('service.care_provider.id');
      var offered_service_id = appointmentRequest.get('offered_service.id');
      var time_frame_start_time = appointmentRequest.get('time_frame_start_time');
      var time_frame_end_time = appointmentRequest.get('time_frame_end_time');
      var date_time = this.get('service.starts_at');
      var special_instructions = appointmentRequest.get('special_instructions');

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests/").concat(appointmentRequest.get('id'), "/approve"),
        method: 'POST',
        data: {
          care_provider_id: care_provider_id,
          offered_service_id: offered_service_id,
          time_frame_start_time: time_frame_start_time,
          time_frame_end_time: time_frame_end_time,
          date_time: date_time,
          special_instructions: special_instructions,
          position: position
        }
      }).then(function (response) {
        if (appointmentRequest.get('offered_service.all_day_service')) {
          _this.get('store').pushPayload(response);
        } else {
          _this.get('service').reload();
        }

        appointmentRequest.deleteRecord();
      });
    },
    moveAppointment: function moveAppointment(groupedAppointment, position) {
      // let service = this.get('service');
      var previousService = groupedAppointment.get('service');
      var previousPosition = groupedAppointment.get('position');

      if (this.get('service.all_day_service') != groupedAppointment.get('service.all_day_service')) {
        groupedAppointment.set('movingInFlight', false);
        groupedAppointment.set('service', previousService);
        groupedAppointment.set('position', previousPosition);
        return;
      } // let startsAtString = moment.parseZone(service.get('starts_at')).format('h:mm A');
      // let startTime = moment(startsAtString, 'h:mm A');
      // let firstAppointment = groupedAppointment.get('appointments.firstObject');
      // let timeFrameStart = moment(firstAppointment.get('time_frame_start_time'), 'h:mmA');
      // let timeFrameEnd = moment(firstAppointment.get('time_frame_end_time'), 'h:mmA');
      //
      // if (startTime.isBefore(timeFrameStart) || startTime.isAfter(timeFrameEnd)) {
      //   this.finishMovingAppointment(groupedAppointment, previousService, previousPosition);
      //   this.set('groupedAppointmentBeingMoved', groupedAppointment);
      //   this.set('selectedPosition', position);
      //   this.set('showOutOfTimeWindowModal', true);
      //   this.set('selectedStartTime', startsAtString);
      //   return;
      // }


      this.handleMovingAppointment(groupedAppointment, position);
    },
    handleMovingAppointment: function handleMovingAppointment(groupedAppointment, position) {
      var _this2 = this;

      var service = this.get('service');
      var previousService = groupedAppointment.get('service');
      var previousPosition = groupedAppointment.get('position');
      var care_provider_id = service.get('care_provider.id');

      var starts_at = _moment.default.parseZone(service.get('starts_at')).format();

      var groupedAppts = this.get('service.grouped_appointments');
      groupedAppts.sortBy('position').forEach(function (appt, index) {
        appt.set('position', index);
      });
      groupedAppts.forEach(function (groupedAppt) {
        if (groupedAppt.get('position') >= position && groupedAppt.get('id') != groupedAppointment.get('id')) {
          groupedAppt.set('position', groupedAppt.get('position') + 1);
        }
      });
      var oldPosition = groupedAppointment.get('position');
      var oldServiceId = groupedAppointment.get('service.id');
      groupedAppointment.set('service', this.get('service'));
      groupedAppointment.set('position', position); // This is a quirk in our system. If you are dragging a pet in the same service,
      // the position you're dragging goes away since we hide the pet-item when dragging.

      if (oldPosition <= position && oldServiceId === this.get('service.id')) {
        position -= 1;
      }

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/grouped_appointments/").concat(groupedAppointment.get('id'), "/move"),
        method: 'PUT',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          position: position,
          care_provider_id: care_provider_id,
          starts_at: starts_at
        })
      }).done(function (response) {
        Ember.run.scheduleOnce('afterRender', function () {
          _this2.get('store').pushPayload(response);

          groupedAppts.forEach(function (appt) {
            return appt.rollbackAttributes();
          });
          groupedAppointment.get('appointments').forEach(function (appt) {
            appt.set('time', _moment.default.parseZone(starts_at).format());
          });

          _this2.resizeAppointmentsList();
        });

        if (_this2.isAndroid()) {
          Ember.run.later(function () {
            _this2.finishMovingAppointment(groupedAppointment, null, null);
          }, 1000);
        } else {
          _this2.finishMovingAppointment(groupedAppointment, null, null);
        }
      }).fail(function (data) {
        _this2.finishMovingAppointment(groupedAppointment, previousService, previousPosition);

        if (data && data.responseText) {
          var parsed = JSON.parse(data.responseText);

          if (parsed.errors) {
            _this2.get('flashMessage').error(parsed.errors.service);
          } else if (parsed.message) {
            _this2.get('flashMessage').error(parsed.message);
          } else {
            _this2.get('flashMessage').error('Unexpected error moving appointment. Please try again');
          }
        } else {
          _this2.get('flashMessage').error('Unexpected error moving appointment. Please try again');
        }
      });
    },
    finishMovingAppointment: function finishMovingAppointment(groupedAppointment, previousService, previousPosition) {
      if (previousService) {
        groupedAppointment.set('service', previousService);
        groupedAppointment.get('appointments').forEach(function (appt) {
          appt.set('service', previousService);
        });
      }

      if (previousPosition) {
        groupedAppointment.set('position', previousPosition);
      }

      groupedAppointment.set('movingInFlight', false);
      this.clearAppointmentBeingMoved();
    },
    clearAppointmentBeingMoved: function clearAppointmentBeingMoved() {
      this.set('groupedAppointmentBeingMoved', null);
      this.set('appointmentRequestBeingConfirmed', null);
      this.set('selectedPosition', null);
      this.set('selectedStartTime', null);
    },
    showCompleteAllButton: computed('sortedGroupedAppointments.@each.hasStartedAppointments', 'isComplete', 'media.isDesktop', 'media.isMobile', 'media.isTablet', function () {
      var isComplete = this.get('isComplete');

      if (this.get('showCanceledAppointments')) {
        return false; // on the desktop, we're showing the mark complete button, which is always available
      } else if (this.get('media.isDesktop') && !isComplete) {
        return true; // check to make sure there are no started appointments for the uncomplete button
      } else if (this.get('media.isDesktop') && isComplete) {
        return !this.get('sortedGroupedAppointments').toArray().any(function (groupedAppt) {
          return groupedAppt.get('hasStartedAppointments');
        }); // this else statement will handle mobile and tablet
      } else {
        var showCompleteAll = true;
        this.get('sortedGroupedAppointments').forEach(function (groupedAppt) {
          if (groupedAppt.get('offered_service.service_category.show_check_in_check_out_appointment') || groupedAppt.get('hasStartedAppointments')) {
            showCompleteAll = false;
          }
        });
        return showCompleteAll;
      }
    }),
    actions: {
      markAllComplete: function markAllComplete() {
        var _this3 = this;

        if (inFuture(this.get('service.starts_at'))) {
          Ember.getOwner(this).lookup('controller:schedule').set('showAppointmentInFutureFlash', true);
          return;
        }

        var appts = [];
        this.get('service.sortedGroupedAppointmentsWithoutCanceled').forEach(function (groupedAppt) {
          groupedAppt.get('appointments').forEach(function (appt) {
            appts.push(appt);
          });
        });
        var completed = !this.get('isComplete');
        var appointments = appts.map(function (appt) {
          var showReportCard = appt.get('offered_service.service_category.show_report_card_on_completion');

          if (completed && showReportCard) {
            _this3.queueGroupedAppointment(appt.get('grouped_appointment'));
          }

          return {
            id: appt.get('id'),
            completed: completed
          };
        });
        this.set('updatingAppointments', true);

        var lazyPromise = function lazyPromise() {
          return _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/appointments/complete"),
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              appointments: appointments
            })
          }).then(function (response) {
            var reloadPromises = [];
            response.appointments.forEach(function (appt) {
              var filteredAppt = appts.filterBy('id', "".concat(appt.id)).get('firstObject');

              if (appt.error) {
                Ember.getOwner(_this3).lookup('controller:application').send('notify', {
                  message: appt.error,
                  type: 'user-error'
                });
              }

              reloadPromises.push(filteredAppt.reload());
            });
            Ember.RSVP.Promise.all(reloadPromises).then(function () {
              if (completed) {
                _this3.startGroupedReportCardQueue();
              }
            });
          }).fail(function (error) {
            var message = 'Unexpected error marking appointments complete. Please try again';
            var type = 'error';

            if (error && error.responseText) {
              var parsed = JSON.parse(error.responseText);

              if (parsed.errors) {
                message = parsed.errors.appointments;
              } else if (parsed.message) {
                message = parsed.message;
              }
            }

            Ember.getOwner(_this3).lookup('controller:application').send('notify', {
              message: message,
              type: type
            });
          }).always(function () {
            _this3.set('updatingAppointments', false);
          });
        };

        lazyPromise();
      },
      dropItemAtLastPosition: function dropItemAtLastPosition(groupedAppointment) {
        this.send('dropItem', groupedAppointment, this.get('service.grouped_appointments.length'));
      },
      dragPetStart: function dragPetStart(groupedAppointment) {
        var _this4 = this;

        var startedAppointments = [];
        groupedAppointment.get('appointments').forEach(function (appt) {
          if (appt.get('started_at') && !appt.get('finished_at')) {
            startedAppointments.push(appt);
          }
        });

        if (startedAppointments.length === 0) {
          this.showScheduleTrash();
        }

        this.get('startDraggingPet')(groupedAppointment);

        if (!this.isAndroid()) {
          setTimeout(function () {
            (0, _jquery.default)(_this4.element.querySelectorAll('.is-dragging-object')).hide();

            _this4.resizeAppointmentsList();
          }, 0);
        }
      },
      dragPetEnd: function dragPetEnd() {
        var _this5 = this;

        this.hideScheduleTrash();
        this.get('stopDraggingPet')();
        setTimeout(function () {
          if (!_this5.isAndroid()) {
            (0, _jquery.default)(_this5.element.querySelectorAll('.draggable-object')).show();
          }

          _this5.resizeAppointmentsList();
        }, 0);
      },
      dropItem: function dropItem(appointmentOrRequest, position) {
        var _this6 = this;

        if (appointmentOrRequest.get('movingInFlight')) {
          return;
        }

        this.get('stopDraggingPet')();
        appointmentOrRequest.set('movingInFlight', true);
        var service = this.get('service');

        var starts_at = _moment.default.parseZone(service.get('starts_at')).format();

        this.get('scheduleDragActionService').stopDragging();
        var isAppointment = appointmentOrRequest.get('constructor.modelName') == 'grouped-appointment';
        var dropMethod = isAppointment ? this.moveAppointment : this.confirmRequest;

        var yes = function yes() {
          return dropMethod.bind(_this6)(appointmentOrRequest, position);
        };

        var no = function no() {};

        if (isAppointment && this.get('checkDuplicateAppointmentTimes').isGroupedAppointmentDuplicate(appointmentOrRequest, starts_at)) {
          var message = "".concat(appointmentOrRequest.get('petNames'), " already has an appointment at this time.");
          this.get('flashMessage').confirm(yes, no, message, 'Proceed', 'Cancel');
          appointmentOrRequest.set('movingInFlight', false);
          no();
        } else if (isAppointment && this.get('checkStartedAppointment').hasStartedAppointment(appointmentOrRequest) && !appointmentOrRequest.get('completed')) {
          if (this.get('currentUser.user').shouldSeeMessage('drag-drop-started-appointment')) {
            this.set('showStartedAppointmentMessage', true);
          }

          appointmentOrRequest.set('movingInFlight', false);
          no();
        } else {
          yes();
        }
      },
      clickAppointment: function clickAppointment(appointment) {
        this.clickAppointment(appointment);
      },
      changeCareProvider: function changeCareProvider(care_provider_id) {
        var _this7 = this;

        this.set('service.care_provider_id', care_provider_id);
        var starts_at = this.get('service.starts_at');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/services/").concat(this.get('service.id')),
          method: 'PUT',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            care_provider_id: care_provider_id,
            starts_at: starts_at
          })
        }).then(function (response) {
          var store = _this7.get('store');

          store.pushPayload(response);

          _this7.changeCareProvider(care_provider_id);
        }).fail(function (data) {
          if (data && data.responseText) {
            var parsed = JSON.parse(data.responseText);

            if (parsed.errors) {
              Ember.getOwner(_this7).lookup('controller:application').send('notify', {
                message: parsed.errors.service,
                type: 'error'
              });
            } else if (parsed.message) {
              Ember.getOwner(_this7).lookup('controller:application').send('notify', {
                message: parsed.message,
                type: 'error'
              });
            } else {
              Ember.getOwner(_this7).lookup('controller:application').send('notify', {
                message: 'Unexpected error updating care professional',
                type: 'error'
              });
            }
          } else {
            Ember.getOwner(_this7).lookup('controller:application').send('notify', {
              message: 'Unexpected error updating care professional',
              type: 'error'
            });
          }
        });
      },
      denyUpdateTimeWindow: function denyUpdateTimeWindow() {
        this.set('showOutOfTimeWindowModal', false);
        this.clearAppointmentBeingMoved();
      },
      confirmUpdateTimeWindow: function confirmUpdateTimeWindow() {
        this.set('showOutOfTimeWindowModal', false);

        if (this.get('groupedAppointmentBeingMoved')) {
          this.handleMovingAppointment(this.get('groupedAppointmentBeingMoved'), this.get('selectedPosition'));
        } else {
          this.handleConfirmingRequest(this.get('appointmentRequestBeingConfirmed'), this.get('selectedPosition'));
        }
      },
      hideDragDropRequestMessage: function hideDragDropRequestMessage() {
        this.set('showDragDropRequestMessage', false);
      },
      dismissedDragDropRequestMessage: function dismissedDragDropRequestMessage() {
        this.send('hideDragDropRequestMessage');
        this.get('currentUser.user').reload();
      },
      hideStartedAppointmentMessage: function hideStartedAppointmentMessage() {
        this.set('showStartedAppointmentMessage', false);
      },
      dismissedStartedAppointmentMessage: function dismissedStartedAppointmentMessage() {
        this.send('hideStartedAppointmentMessage');
        this.get('currentUser.user').reload();
      },
      hideAllDayRequestInTimeSlotMessage: function hideAllDayRequestInTimeSlotMessage() {
        this.set('showAllDayRequestInTimeSlotModal', false);
      },
      dismissedAllDayRequestInTimeSlotMessage: function dismissedAllDayRequestInTimeSlotMessage() {
        this.send('hideAllDayRequestInTimeSlotMessage');
        this.get('currentUser.user').reload();
      }
    }
  });

  _exports.default = _default;
});