define("frontend/components/holiday-surcharge-holiday-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showSwitches: true,
    holidaySurchargePolicy: null,
    availableHolidayDates: null,
    holidayDates: Ember.computed('availableHolidayDates', 'holidaySurchargePolicy.year', function () {
      var availableHolidayDates = this.get('availableHolidayDates');
      var year = this.get('holidaySurchargePolicy.year');

      if (availableHolidayDates) {
        return availableHolidayDates[year] ? availableHolidayDates[year].withData : [];
      } else {
        return [];
      }
    })
  });

  _exports.default = _default;
});