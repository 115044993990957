define("frontend/components/sign-contracts-modal", ["exports", "frontend/config/environment", "frontend/utils/string-util", "jquery"], function (_exports, _environment, _stringUtil, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed,
      observer = Ember.observer,
      inject = Ember.inject;

  var redirectUserTo = function redirectUserTo(path) {
    var _window$location = window.location,
        hostname = _window$location.hostname,
        origin = _window$location.origin;
    var url = "".concat(origin).concat(path);

    if (hostname.includes("localhost")) {
      url = "http://".concat(hostname, ":4200").concat(path);
    }

    window.location.replace(url);
  };

  var _default = Ember.Component.extend({
    currentUser: inject.service(),
    reactApp: inject.service(),
    media: inject.service(),
    router: inject.service(),
    isMobile: computed.alias('media.isMobile'),
    signatureRequests: [],
    signatureRequestsCopy: [],
    companyName: computed.alias('signatureRequests.firstObject.company'),
    signatureUrl: computed.alias('signatureRequests.firstObject.signature_url'),
    showNonMobilePdfButton: false,
    currentSignatureRequest: null,
    isIPad: computed(function () {
      //Older iPads have a user agent of iPad while newer ones use Macintosh
      return /iPad/i.test(navigator.userAgent) || /Mac/i.test(navigator.userAgent) && navigator.maxTouchPoints > 1;
    }),
    pdfURL: computed.alias('currentSignatureRequest.pdf_document_url'),
    pdfURLForMobile: computed('pdfURL', function () {
      var encodedUrl = encodeURIComponent(this.get('pdfURL'));
      return "https://docs.google.com/viewer?url=".concat(encodedUrl, "&embedded=true");
    }),
    urlForIFrame: computed('isMobile', 'isIPad', 'pdfURL', 'pdfURLForMobile', function () {
      var useGoogleViewer = this.get('isMobile') || this.get('isIPad');
      return useGoogleViewer ? this.get('pdfURLForMobile') : this.get('pdfURL');
    }),
    signatureName: null,
    signing: false,
    attemptedSign: false,
    vetLimitAmount: null,
    signContractParam: null,
    householdId: null,
    useSharedResourceLanguage: computed('signatureRequests', 'signContractParam', function () {
      var signatureRequests = this.get('signatureRequests');
      var isSharedResource = signatureRequests.firstObject.document_type === 'shared resource';
      var signContractParam = this.get('signContractParam');
      return signContractParam && isSharedResource || signatureRequests.length === 1 && isSharedResource;
    }),
    loadingIframe: false,
    filterSignatureRequestsWithCategoryID: null,
    appointmentRequestCategoryID: null,
    appointmentRequestCompanyID: null,
    appointmentRequestPetIDs: null,
    companyNames: computed('signatureRequests.@each.company', function () {
      var companies = this.get('signatureRequests').mapBy('company');

      var uniqueCompanies = _toConsumableArray(new Set(companies));

      return _stringUtil.default.joinWithAnd(uniqueCompanies);
    }),
    requestsToSignMessage: computed('signatureRequests', 'companyNames', function () {
      var requests = this.get('signatureRequests');
      var companyNames = this.get('companyNames');
      var defaultMessage = "".concat(companyNames, " has a document for you to review!");

      if (requests.length === 1) {
        var requestType = requests.firstObject.document_type;
        var messages = {
          'service agreement': "".concat(companyNames, " has a service agreement for you to review and sign!"),
          'vet release & waiver': "".concat(companyNames, " has a vet release & waiver for you to review and sign!"),
          'optional release': "".concat(companyNames, " has a release for you to review and sign!"),
          'shared resource': defaultMessage
        };
        return messages[requestType] || defaultMessage;
      }

      return "".concat(companyNames, " has a few documents for you to review and sign before proceeding!");
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var idOfRequestToSign = this.get('signContractParam');
      var apptRqstCtgryID = this.get('appointmentRequestCategoryID');

      if ((idOfRequestToSign || apptRqstCtgryID) && this.get('isMobile')) {
        setTimeout(function () {
          (0, _jquery.default)('.portal-appointments-page').css('height', 0);
        }, 1000);
      }

      var requests = this.get('signatureRequests');

      if (idOfRequestToSign && requests.length === 1) {
        this.set('currentSignatureRequest', requests.firstObject);
      }

      if (apptRqstCtgryID) {
        this.set('currentSignatureRequest', requests.firstObject);
      }
    },
    observeCurrentSignatureRequest: observer('currentSignatureRequest', function () {
      var _this = this;

      if (this.get('currentSignatureRequest')) {
        var urlForIFrame = this.get('urlForIFrame');
        var pdfURL = this.get('pdfURL');
        this.set('loadingIframe', true);

        if (urlForIFrame && pdfURL) {
          (0, _jquery.default)('#iframeContainer').empty();
          (0, _jquery.default)('.iframe-loader').css('display', 'block');

          var loadIFrame = function loadIFrame() {
            var iframe = document.getElementById("myIframe");

            if (iframe && iframe.contentWindow && iframe.contentWindow.document.body) {
              (0, _jquery.default)('.iframe-loader').css('display', 'none');
              clearInterval(loadIFrameInterval);
            } else {
              var iframeHtml = "<iframe id=\"myIframe\" src=\"".concat(urlForIFrame, "\" style=\"width:100%; height:100%; border: none;\"></iframe>");
              (0, _jquery.default)('#iframeContainer').html(iframeHtml);
              count++;

              if (count >= 20) {
                clearInterval(loadIFrameInterval);
                (0, _jquery.default)('.iframe-loader').css('display', 'none');
                (0, _jquery.default)('#iframeContainer').remove();

                _this.set('showNonMobilePdfButton', true);
              }
            }
          };

          var count = 0;
          var loadIFrameInterval = setInterval(loadIFrame, 250);
        }
      }
    }),
    signContractParamObserver: observer('signContractParam', 'appointmentRequestCategoryID', function () {
      if ((this.get('signContractParam') || this.get('appointmentRequestCategoryID')) && this.get('isMobile')) {
        setTimeout(function () {
          (0, _jquery.default)('.portal-appointments-page').css('height', 0);
        }, 1000);
      }
    }),
    vetAmountErrorObserver: observer('vetLimitAmount', 'attemptedSign', function () {
      var show = this.get('attemptedSign') && (this.get('vetLimitAmount') == null || this.get('vetLimitAmount') <= 0);
      this.set('showVetAmountError', show);
    }),
    actions: {
      viewDocument: function viewDocument() {
        var url = this.get('pdfURL');

        if (url) {
          window.open(url, '_blank', 'noopener,noreferrer');
        }
      },
      close: function close() {
        this.get('onClose')();
      },
      toggleVetLimitSwitch: function toggleVetLimitSwitch() {
        this.toggleProperty('vetLimitEnabled');

        if (!this.get('vetLimitEnabled')) {
          this.set('vetLimitAmount', null);
        }
      },
      reviewContractsLater: function reviewContractsLater() {
        this.get('reviewContractsLater')();
      },
      completedSigningForSpecificCategory: function completedSigningForSpecificCategory() {
        this.get('completedSigningForSpecificCategory')();
        this.send('reviewContractsLater');
      },
      openSignatureRequest: function openSignatureRequest() {
        this.set('currentSignatureRequest', this.get('signatureRequests.firstObject'));
      },
      signDocument: function signDocument() {
        var _this2 = this;

        var agreedToRelease = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (this.get('signing')) {
          return;
        }

        this.set('attemptedSign', true);
        var index = this.get('signatureRequests').indexOf(this.get('currentSignatureRequest'));
        this.set('signing', true);

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/sign_contract",
          method: 'POST',
          data: {
            signature_request_id: this.get('currentSignatureRequest.id'),
            signature_name: this.get('signatureName'),
            agreed_to_release: agreedToRelease,
            vet_limit_amount: this.get('vetLimitAmount')
          }
        }).then(function () {
          _this2.set('signing', false);

          _this2.set('attemptedSign', false);

          if (index < _this2.get('signatureRequests.length') - 1) {
            _this2.set('currentSignatureRequest', _this2.get('signatureRequests').objectAt(index + 1));

            _this2.set('signatureName', null);
          } else {
            _this2.get('currentUser').set('hasAgreementsToReview', false);

            var signContractParam = _this2.get("signContractParam");

            var apptRqstCtgryID = _this2.get("appointmentRequestCategoryID");

            var householdId = _this2.get("householdId");

            if (signContractParam) {
              var reactPortalDocsPath = "/react/portal/".concat(householdId, "/documents");
              redirectUserTo(reactPortalDocsPath);
            } else if (apptRqstCtgryID) {
              var apptRqstCmpnyID = _this2.get('appointmentRequestCompanyID');

              var apptRqstPtIDs = _this2.get('appointmentRequestPetIDs').split(',');

              var _apptRqstCtgryID = _this2.get('appointmentRequestCategoryID');

              window.location.href = _this2.get('reactApp').getAppointmentRequestUrl(apptRqstCmpnyID, null, null, apptRqstPtIDs, _apptRqstCtgryID);
            } else {
              if (_this2.get('filterSignatureRequestsWithCategoryID')) {
                _this2.send('completedSigningForSpecificCategory');
              } else {
                window.location.reload();
              }
            }
          }
        }).catch(function (e) {
          console.log('IN CATCH BLOCK', e);
        });
      }
    }
  });

  _exports.default = _default;
});