define("frontend/routes/companies/category-settings", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      inject = _Ember.inject,
      computed = _Ember.computed;

  var _default = _baseRoute.default.extend({
    company: computed.readOnly('currentUser.user.company'),
    holidayDatesService: inject.service(),
    model: function model() {
      this._super.apply(this, arguments);

      return this.store.query('service-category', {}, {
        reload: true
      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      var page = transition.to.params.page;
      controller.set('onTab', page);
      controller.set('loading', false);
      this.get('holidayDatesService').getHolidays(this.get('company.country'), this.get('company.state')).then(function (holidayDates) {
        controller.set('availableHolidayDates', holidayDates);
        controller.setupHolidaySurchargePolicy();
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        return this.refresh();
      }
    }
  });

  _exports.default = _default;
});