define("frontend/components/offered-service-modal", ["exports", "frontend/config/environment", "jquery", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _environment, _jquery, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend(_highlightFirstMatch.default, {
    classNames: ["add-with-side-panel"],
    offeredService: null,
    colorLines: [["BEDBF3", "FAC9C8", "DBDCDE", "FFE4B4", "A4D9D0", "DEC7FF", "FFC7F2", "FAD8C8"], ["27A9E1", "F05860", "9D9FA1", "FEC23B", "51C1B0", "AB70FF", "FF70D9", "F09958"], ["0077B2", "D31436", "484849", "B77F00", "009D87", "6227B6", "B6278D", "E26503"]],
    capacityTypes: [{
      label: "families at once",
      value: "per_family"
    }, {
      label: "pets at once",
      value: "per_pet"
    }],
    dogPlaceholder: "assets/images/placeholders/Dog.svg",
    appointmentDurationsInfo: inject.service(),
    durationOptions: computed.readOnly("appointmentDurationsInfo.durations"),
    company: null,
    capacitySwitch: computed.notEmpty("offeredService.capacity_type"),
    willDestroyElement: function willDestroyElement() {
      this.set("loading", false);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var offeredService = this.get("offeredService");
      var isNew = offeredService.isNew;
      this.set("startingAllDaySwitch", this.get("offeredService.all_day_service"));
      var isNonMisc = offeredService.service_category.get("name") !== "Miscellaneous";

      if (isNew && isNonMisc) {
        this.set("offeredService.apply_sales_tax", true);
      }
    },
    priceHasChanged: function priceHasChanged(price) {
      if (price == null) {
        return false;
      }

      var _price = _slicedToArray(price, 2),
          oldPrice = _price[0],
          newPrice = _price[1];

      if (Ember.isEmpty(oldPrice) && Ember.isEmpty(newPrice)) {
        return false;
      }

      oldPrice = "".concat(oldPrice);
      newPrice = "".concat(newPrice);
      return oldPrice != newPrice;
    },
    isInvalid: computed.or("offeredService.validations.attrs.price.isInvalid", "offeredService.validations.attrs.duration_in_minutes.isInvalid"),
    durationInMinutes: computed("offeredService.duration_in_minutes", function () {
      var _this = this;

      if (this.get("offeredService.duration_in_minutes") || this.get("offeredService.duration_in_minutes") == 0) {
        return this.get("durationOptions").filter(function (duration) {
          return duration.value == _this.get("offeredService.duration_in_minutes");
        }).get("firstObject");
      }
    }),
    effectiveDateFieldTouched: false,
    showIncrementalPriceError: computed("validated", "offeredService.incremental_price", function () {
      return this.get("validated") && (this.get("offeredService.incremental_price") == null || this.get("offeredService.validations.attrs.incremental_price.isInvalid"));
    }),
    effectiveDateValue: computed("offeredService.price_start_date", function () {
      var momentifiedStartDate = moment(this.get("offeredService.price_start_date"));
      var isFutureDate = momentifiedStartDate > moment();
      if (isFutureDate) return momentifiedStartDate.format("MMMM D, YYYY");
      return '';
    }),
    effectiveDateHelperText: computed("effectiveDateValue", function () {
      var firstPart = "Your new prices will apply starting with each client's first bill that starts on or after";
      var endPart = "Clients with custom pricing for this service will not be affected.";
      var effectiveDateValue = this.get("effectiveDateValue");

      if (effectiveDateValue) {
        var formattedDate = moment(effectiveDateValue).format('MMM D');

        if (!(moment(effectiveDateValue) > moment())) {
          return "".concat(firstPart, " ").concat(formattedDate, ". We noticed that you selected a date in the past. Please note that your price changes will not affect any bills that have already been sent.");
        }

        return "".concat(firstPart, " ").concat(formattedDate, ". ").concat(endPart);
      }

      return "".concat(firstPart, " your selected date. ").concat(endPart);
    }),
    showEffectiveDateField: computed("offeredService.price", "offeredService.incremental_price", "offeredService.isNew", "offeredService.price_start_date", function () {
      var isExistingService = !this.get("offeredService.isNew");
      var changedAttributes = this.get("offeredService").changedAttributes();
      var pricesHaveChanged = this.priceHasChanged(changedAttributes.price) || this.priceHasChanged(changedAttributes.incremental_price);
      var isFutureDate = moment(this.get("offeredService.price_start_date")) > moment();
      if (isFutureDate) return true;
      if (isExistingService && pricesHaveChanged) return true;
      return false;
    }),
    formattedEffectiveDate: computed("offeredService.effectiveDate", function () {
      var effectiveDate = this.get("offeredService.effectiveDate");

      if (effectiveDate) {
        return moment(effectiveDate).format("YYYY-MM-DD");
      }

      return null;
    }),
    userShouldHaveChosenAnEffectiveDateButDidNot: computed("showEffectiveDateField", "effectiveDateValue", function () {
      var showEffectiveDateField = this.get("showEffectiveDateField");
      var effectiveDateValue = this.get("effectiveDateValue");
      return showEffectiveDateField && !effectiveDateValue;
    }),
    effectiveDateBeforeToday: computed("offeredService.effectiveDate", function () {
      var effectiveDate = this.get("offeredService.effectiveDate");

      if (effectiveDate) {
        return moment(effectiveDate) < moment().startOf("day");
      }

      return false;
    }),
    selectedCapacityType: computed("offeredService.capacity_type", function () {
      var _this2 = this;

      return this.get("capacityTypes").filter(function (type) {
        return type.value == _this2.get("offeredService.capacity_type");
      }).get("firstObject");
    }),
    chargeAdditionalRateClassName: computed("offeredService.show_to_pet_parents", function () {
      return this.get("offeredService.show_to_pet_parents") ? "middle-switch" : "bottom-switch";
    }),
    capacitySwitchClassName: computed("capacitySwitch", function () {
      return this.capacitySwitch ? "bottom-switch no-bottom-border" : "bottom-switch";
    }),
    tagToSalesTaxSettings: computed("company.id", function () {
      var id = this.get("company.id");
      return "<a href=\"companies/".concat(id, "/billing/getting-paid\">Tap here</a> to update the sales tax % that you need to collect");
    }),
    allowClientsToRequestDisabled: computed('offeredService.all_day_service', 'offeredService.service_category', function () {
      return this.get('offeredService.all_day_service') && this.get('offeredService.service_category.name') !== 'Overnights';
    }),
    actions: {
      cancel: function cancel() {
        if (this.get("showEffectiveDateCalendar")) {
          this.send("closeEffectiveDateSidePanel");
        } else if (this.get("showSwitchToAllDayModal")) {
          this.send("closeSwitchToAllDayModal");
        } else if (this.get("showAllDayErrorModal")) {
          this.send("closeAllDayErrorModal");
        } else {
          this.cancel();
        }
      },
      save: function save() {
        var _this3 = this;

        var effectiveDateValue = this.get('effectiveDateValue');

        if (effectiveDateValue) {
          this.set("offeredService.effectiveDate", effectiveDateValue);
          this.set("offeredService.price_start_date", moment(effectiveDateValue).toDate());
        }

        if (this.get("userShouldHaveChosenAnEffectiveDateButDidNot")) {
          this.set("effectiveDateFieldTouched", true);
        }

        this.set("validated", true);

        if (this.get("isInvalid") || this.get("showIncrementalPriceError") || this.get('userShouldHaveChosenAnEffectiveDateButDidNot')) {
          return;
        }

        if (this.get("offeredService.capacity_type")) {
          var cap = this.get("offeredService.capacity_cap");

          if (!cap) {
            return;
          }

          cap = parseInt(cap);

          if (cap < 1) {
            return;
          }
        }

        if (!this.get("offeredService.isNew") && this.get("offeredService.all_day_service") && !this.get("startingAllDaySwitch")) {
          this.set("loading", true);

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/offered_services/").concat(this.get("offeredService.id"), "/has_any_appointments"),
            method: "GET"
          }).then(function (response) {
            if (response.has_any_appointments) {
              _this3.set("showSwitchToAllDayModal", true);
            } else {
              _this3.save(_this3.get("offeredService"));
            }

            _this3.set("loading", false);
          });
        } else {
          this.set("loading", true);
          this.save(this.get("offeredService"));
        }
      },
      delete: function _delete() {
        this.delete(this.get("offeredService"));
      },
      changeDates: function changeDates(date) {
        this.set("effectiveDateValue", date.format("MMMM D, YYYY"));
        this.set("showEffectiveDateCalendar", false);
      },
      openEffectiveDateSidePanel: function openEffectiveDateSidePanel() {
        this.set("showEffectiveDateCalendar", true);
      },
      closeEffectiveDateSidePanel: function closeEffectiveDateSidePanel() {
        this.set("effectiveDateFieldTouched", true);
        this.set("showEffectiveDateCalendar", false);
      },
      setColorHex: function setColorHex(colorHex) {
        this.set("offeredService.color_hex", colorHex);
      },
      changeShowToPetParentsSwitch: function changeShowToPetParentsSwitch() {
        this.toggleProperty("offeredService.show_to_pet_parents");

        if (!this.get("offeredService.show_to_pet_parents")) {
          this.set("offeredService.capacity_type", null);
        }
      },
      changeApplySalesTaxSwitch: function changeApplySalesTaxSwitch() {
        this.toggleProperty("offeredService.apply_sales_tax");
      },
      changeAddOnSwitch: function changeAddOnSwitch() {
        this.toggleProperty("offeredService.is_add_on");
      },
      changeAllDaySwitch: function changeAllDaySwitch() {
        if (this.get("offeredService.all_day_service") && this.get("offeredService.has_any_appointments")) {
          this.set("showAllDayErrorModal", true);
        } else {
          this.toggleProperty("offeredService.all_day_service");

          if (this.get("offeredService.all_day_service")) {
            if (this.get('offeredService.service_category.name') !== 'Overnights') {
              this.set("offeredService.show_to_pet_parents", false);
            }

            if (!this.get("offeredService.duration_in_minutes")) {
              this.set("offeredService.duration_in_minutes", 0);
              this.set("durationInMinutes", this.get("durationOptions.firstObject"));
            }
          }
        }
      },
      updateSelectedStartTime: function updateSelectedStartTime(time) {
        this.set("offeredService.new_start_time", time);

        if (time) {
          this.set("showSelectedStartTimeError", false);
        }
      },
      closeAllDayErrorModal: function closeAllDayErrorModal(turnOff) {
        if (turnOff) {
          if (!this.get("offeredService.new_start_time")) {
            this.set("showSelectedStartTimeError", true);
          } else {
            this.set("offeredService.all_day_service", false);
            this.toggleProperty("showAllDayErrorModal");
          }
        } else {
          this.toggleProperty("showAllDayErrorModal");
        }
      },
      closeSwitchToAllDayModal: function closeSwitchToAllDayModal() {
        this.toggleProperty("offeredService.all_day_service");
        this.toggleProperty("showSwitchToAllDayModal");
      },
      saveFromSwitchAllDay: function saveFromSwitchAllDay() {
        this.save(this.get("offeredService"));
      },
      updateDuration: function updateDuration(duration) {
        if (duration) {
          this.set("offeredService.duration_in_minutes", duration.value);
        } else {
          this.set("offeredService.duration_in_minutes", null);
        }

        this.set("durationInMinutes", duration);
      },
      searchDurations: function searchDurations(term) {
        return this.get("durationOptions").filter(function (duration) {
          return duration.value == term || duration.label.includes(term);
        });
      },
      changeCategory: function changeCategory(category) {
        this.set("offeredService.service_category", category);
        this.set("offeredService.show_to_pet_parents", category.get("show_to_pet_parents"));
      },
      changeCapacityType: function changeCapacityType(capacityType) {
        this.set("offeredService.capacity_type", capacityType.value);
      },
      changeCapacitySwitch: function changeCapacitySwitch() {
        if (this.get("offeredService.capacity_type")) {
          this.set("offeredService.capacity_type", null);
        } else {
          var defaultType = this.get("capacityTypes.firstObject.value");
          this.set("offeredService.capacity_type", defaultType);
        }
      }
    }
  });

  _exports.default = _default;
});