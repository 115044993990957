define("frontend/controllers/client-list/household", ["exports", "frontend/utils/scroll-util", "frontend/config/environment"], function (_exports, _scrollUtil, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer;
  var EDIT_PROFILES = "edit-profiles";
  var ADD_APPOINTMENT = "add-appointment";
  var VIEW_BILLING_SETTINGS = "view-billing-settings";
  var UPLOAD_DOCUMENTS = "upload-documents";
  var NOTES_SCROLL = ".notes-output header";
  var MEDICAL_SCROLL = ".medical-output header";
  var BEHAVIORAL_SCROLL = ".behavioral-output header";
  var TEXT_HEIGHT = 20;
  var MARGIN_TOP = 40;

  var _default = Ember.Controller.extend({
    householdUserController: inject.controller("client-list.household.household-user"),
    petController: inject.controller("client-list.household.pet"),
    clientList: inject.controller(),
    filter: computed.alias("clientList.filter"),
    application: inject.controller(),
    firestore: inject.service(),
    firestoreListener: null,
    router: inject.service(),
    media: inject.service(),
    hasMedical: computed.readOnly("petController.hasMedical"),
    hasBehavioral: computed.readOnly("petController.hasBehavioral"),
    hasNotes: computed.readOnly("petController.hasNotes"),
    hasAnyInfo: computed.readOnly("petController.hasAnyInfo"),
    currentRouteName: computed.readOnly("router.currentRouteName"),
    onHouseholdUserRoute: computed.equal("currentRouteName", "client-list.household.household-user"),
    onPetRoute: computed.equal("currentRouteName", "client-list.household.pet"),
    onAboutRoute: computed.or("onPetRoute", "onHouseholdUserRoute"),
    onDocumentRoute: computed.equal("currentRouteName", "client-list.household.documents"),
    onBillingRoute: computed.equal("currentRouteName", "client-list.household.billing"),
    onAppointmentsRoute: computed.equal("currentRouteName", "client-list.household.appointments"),
    onMessageRoute: computed.equal("currentRouteName", "client-list.household.messages"),
    selectedSection: computed.alias("petController.selectedSection"),
    notesActive: computed.equal("selectedSection", "notes"),
    medicalActive: computed.equal("selectedSection", "medical"),
    behavioralActive: computed.equal("selectedSection", "behavioral"),
    moreMenuOpen: false,
    showLoadMore: true,
    removingAppointment: null,
    mobileTabBarIsShowing: false,
    currentUser: Ember.inject.service(),
    moreOptions: computed("currentUser.user.isAdmin", function () {
      var options = [];

      if (this.get("currentUser.user.isAdmin")) {
        options = [{
          label: "Add appointment",
          value: ADD_APPOINTMENT
        }, {
          label: "Edit profiles",
          value: EDIT_PROFILES
        }, {
          label: "View billing settings",
          value: VIEW_BILLING_SETTINGS
        }];
      } else if (this.get("currentUser.user.canAddEditAppointments")) {
        options.push({
          label: "Add appointment",
          value: ADD_APPOINTMENT
        });
      }

      options.push({
        label: "See documents",
        value: UPLOAD_DOCUMENTS
      });
      return options;
    }),
    selectedUser: computed("currentRouteName", "householdUserController.model", "petController.model", function () {
      if (this.get("currentRouteName") === "client-list.household.household-user") {
        return this.get("householdUserController.model");
      } else {
        return this.get("petController.model");
      }
    }),
    mobileNavText: computed("model.owner.last_name", "mobileTabBarIsShowing", "selectedUser", function () {
      var tabIsShowing = this.get("mobileTabBarIsShowing");
      var selectedUser = this.get("selectedUser");
      var familyNameText = "".concat(this.get("model.owner.last_name"), " family");
      if (!selectedUser) return familyNameText;
      var petName = this.get("selectedUser").name;
      var aboutPetText = "about ".concat(petName);
      return tabIsShowing && aboutPetText ? aboutPetText : familyNameText;
    }),
    selectedUserEmail: computed("selectedUser", function () {
      var user = this.get("selectedUser");

      if (user.get("modelName") == "household-user") {
        return user.get("email");
      }

      return this.get("model.owner.email");
    }),
    clientPending: computed.alias("model.isPendingStatus"),
    clientNotLocked: computed.not("model.locked"),
    showAppointments: computed.not("currentUser.user.isStaff"),
    showEditIcon: computed.and("currentUser.user.canAddEditClients", "clientNotLocked"),
    showMenu: false,
    showAppointmentsMenuItem: computed.alias("currentUser.user.canViewFullClientInfo"),
    showBillingMenuItem: computed.alias("currentUser.user.canViewInvoicesTab"),
    showRemoveFamilyMenuItem: computed.alias("currentUser.user.isAdmin"),
    menuSpacing: computed("showAppointmentsMenuItem", "showBillingMenuItem", "showRemoveFamilyMenuItem", function () {
      var dynamicMenuItems = [this.get("showAppointmentsMenuItem"), this.get("showBillingMenuItem"), this.get("showRemoveFamilyMenuItem")].filter(Boolean).length;
      return Ember.String.htmlSafe("bottom: -".concat(dynamicMenuItems * 38 + 89, "px"));
    }),
    scrollTo: function scrollTo(selector) {
      var headerHeight = document.querySelector(".flex-header").clientHeight - MARGIN_TOP;
      var topOfElement = document.querySelector(selector).offsetTop - headerHeight;
      document.querySelector(".page-wrap").scroll({
        top: topOfElement
      });
    },
    modelObserver: observer("model.id", function () {
      if (this.get("firestoreListener")) {
        this.get("firestoreListener")();
      }

      var self = this;
      var collection = this.get("firestore").getCollection("".concat(_environment.default.environment, "_message_times"));

      if (collection) {
        var firestoreListener = collection.doc("".concat(self.get("currentUser.user.company.id"), "_").concat(self.get("model.id"))).onSnapshot(function (doc) {
          if (doc.exists) {
            self.set("lastMessageTime", doc.data().staff_last_message);
          } else {
            self.set("lastMessageTime", null);
          }
        });
        this.set("firestoreListener", firestoreListener);
      }
    }),
    hasNewMessage: computed("model.user_last_seen_message_thread", "lastMessageTime", function () {
      if (!this.get("lastMessageTime")) {
        return false;
      }

      var lastSeen = this.get("model.user_last_seen_message_thread");
      var lastMessage = this.get("lastMessageTime");

      if (!lastSeen) {
        return true;
      }

      return moment(lastSeen).unix() < lastMessage && !this.get("onMessageRoute");
    }),
    actions: {
      clickPetOrUser: function clickPetOrUser(petOrUser) {
        if (petOrUser.get("modelName") === "household-user") {
          this.transitionToRoute("client-list.household.household-user", this.get("model.id"), petOrUser);
        } else {
          this.transitionToRoute("client-list.household.pet", this.get("model.id"), petOrUser);
        }
      },
      goToEditProfile: function goToEditProfile() {
        this.toggleProperty("showMenu");
        this.send("transitionToEditHousehold", this.get("model"), this.get("selectedUser"));
      },
      goToAppointments: function goToAppointments() {
        this.toggleProperty("showMenu");
        this.transitionToRoute("client-list.household.appointments", this.get("model"));
      },
      goToBilling: function goToBilling() {
        this.toggleProperty("showMenu");
        this.transitionToRoute("invoices-summary", this.get("model.id"));
      },
      goToDocuments: function goToDocuments() {
        this.toggleProperty("showMenu");
        this.transitionToRoute("client-list.household.documents", this.get("model"));
      },
      removeFamily: function removeFamily(model) {
        this.toggleProperty("showMenu");
        this.send("removeHousehold", model);
      },
      clickBack: function clickBack() {
        this.set("showMenu", false);
        this.send("goBack");
      },
      scroll: function scroll() {
        if (this.get("media.isMobile")) {
          var petNameElement = $(".pet-or-user-information__full-name");

          if (petNameElement.length > 0) {
            var petNameTop = petNameElement.offset().top;

            if (petNameTop <= 75 && this.get("onPetRoute")) {
              this.set("mobileTabBarIsShowing", true);
            } else {
              this.set("mobileTabBarIsShowing", false);
            }
          } else {
            console.warn("Element .pet-or-user-information__full-name not found");
          }

          return;
        }

        var headerHeight = document.querySelector(".flex-header").clientHeight - TEXT_HEIGHT;

        if (_scrollUtil.default.isScrolledIntoView(NOTES_SCROLL, headerHeight)) {
          this.set("selectedSection", "notes");
        } else if (_scrollUtil.default.isScrolledIntoView(BEHAVIORAL_SCROLL, headerHeight)) {
          this.set("selectedSection", "behavioral");
        } else if (_scrollUtil.default.isScrolledIntoView(MEDICAL_SCROLL, headerHeight)) {
          this.set("selectedSection", "medical");
        }
      },
      clickMedical: function clickMedical() {
        this.scrollTo(MEDICAL_SCROLL);
        var self = this; // scrollTo will trigger scroll that overrides selected section if
        // multiple sections are in view so set selected section on a delay

        setTimeout(function () {
          self.set("selectedSection", "medical");
        }, 50);
      },
      clickBehavioral: function clickBehavioral() {
        this.scrollTo(BEHAVIORAL_SCROLL);
        var self = this;
        setTimeout(function () {
          self.set("selectedSection", "behavioral");
        }, 50);
      },
      clickNotes: function clickNotes() {
        this.scrollTo(NOTES_SCROLL);
        var self = this;
        setTimeout(function () {
          self.set("selectedSection", "notes");
        }, 50);
      },
      openMoreMenu: function openMoreMenu() {
        this.set("moreMenuOpen", true);
      },
      clickMoreOption: function clickMoreOption(option) {
        var _this = this;

        var value = option.value;

        switch (value) {
          case EDIT_PROFILES:
            this.send("transitionToEditHousehold", this.get("model"), this.get("selectedUser"));
            break;

          case VIEW_BILLING_SETTINGS:
            this.transitionToRoute("client-list.household.billing", this.get("model")).then(function () {
              _this.set("moreMenuOpen", false);
            });
            break;

          case UPLOAD_DOCUMENTS:
            this.transitionToRoute("client-list.household.documents", this.get("model")).then(function () {
              _this.set("moreMenuOpen", false);
            });
            break;

          case ADD_APPOINTMENT:
            this.send("transitionToAddAppointment");
            break;
        }
      },
      clickMoreBack: function clickMoreBack() {
        this.set("moreMenuOpen", false);
      }
    }
  });

  _exports.default = _default;
});