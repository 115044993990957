define("frontend/components/auto-pay-with-recurring-tip", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    classNames: ["auto-pay-with-recurring-tip"],
    store: inject.service(),
    media: inject.service(),
    currentUser: inject.service(),
    cookies: inject.service(),
    router: inject.service(),
    isPetParentUser: computed("cookies", function () {
      return this.get("cookies").read("ccontrol_id") === "0";
    }),
    settings: null,
    household: null,
    clientAccountBalance: computed("household.account_balance", "settings.account_balance", "isPetParentUser", function () {
      if (this.get("isPetParentUser")) {
        return parseFloat(this.get("settings.account_balance")).toFixed(2);
      }

      return parseFloat(this.get("household.account_balance")).toFixed(2);
    }),
    clientHasAccountBalance: computed("clientAccountBalance", function () {
      return this.get("clientAccountBalance") > 0;
    }),
    companyId: null,
    showAutoPayModal: false,
    autoPayIsOn: computed.readOnly("settings.auto_pay"),
    useAutoPayRecurringTip: computed.notEmpty("settings.auto_pay_tip_percentage"),
    autoPayTipPercentageClarification: computed("autoPayRecurringTipInputValue", "household.pets", "settings.service_fees_on", function () {
      var percentage = this.get("autoPayRecurringTipInputValue");
      var companyIsUsingServiceFees = this.get("settings.service_fees_on");

      if (percentage) {
        return "We will automatically include a ".concat(percentage, "% tip to your care professional when charging your payment method on file for new invoices.");
      }

      var petClarification = this.get("household.pets").length > 1 ? "pets" : "pet";
      return "Include a set % in tip when your payment method on file is automatically charged with each invoice. ".concat(companyIsUsingServiceFees ? "100%" : "This", " will go to the professionals who cared for your ").concat(petClarification, ".");
    }),
    showPercentageInputError: false,
    autoPayRecurringTipInputValue: computed("settings.auto_pay_tip_percentage", function () {
      return this.get("settings.auto_pay_tip_percentage");
    }),
    showAutoPayTipPercentageInput: computed("autoPayRecurringTipInputValue", function () {
      var val = this.get("autoPayRecurringTipInputValue");
      return val !== null && val !== 0;
    }),
    resetInputValidationError: observer("autoPayRecurringTipInputValue", function () {
      this.set("showPercentageInputError", false);
    }),
    disableRecurringTipToggle: computed.not("settings.auto_pay"),
    isMobile: computed.readOnly("media.isMobile"),
    invoiceFrequencyClarification: computed("settings.invoice_frequency", function () {
      switch (this.get("settings.invoice_frequency")) {
        case "monthly":
          return "on the first of each month";

        case "semimonthly":
          return "on the 1st and 15th of each month";

        case "biweekly":
          return "every other Monday morning";

        case "weekly":
          return "every Monday morning";

        case "daily":
          return "every morning";

        default:
          return null;
      }
    }),
    autoPayClarification: computed("invoiceFrequencyClarification", "autoPayIsOn", "settings.service_fees_on", "isPetParentUser", function () {
      var invoiceFrequencyClarification = this.get("invoiceFrequencyClarification");
      var autoPayIsOn = this.get("autoPayIsOn");
      var beginningOfMsg = autoPayIsOn ? "We will" : "Enabling AutoPay will allow us to";
      var endOfMsg = "automatically charge the payment method on file for the full outstanding balance ".concat(invoiceFrequencyClarification, " and send an itemized receipt via email.");
      var companyIsUsingServiceFees = this.get("settings.service_fees_on");
      var isPetParentUser = this.get("isPetParentUser");

      if (companyIsUsingServiceFees && isPetParentUser) {
        endOfMsg += " A 5% service fee will be added to help us cover operating costs.";
      }

      return "".concat(beginningOfMsg, " ").concat(endOfMsg);
    }),
    baseAutoPayModalMessage: computed("isPetParentUser", "invoiceFrequencyClarification", function () {
      var isPetParentUser = this.get("isPetParentUser");
      var invoiceFrequencyClarification = this.get("invoiceFrequencyClarification");
      var whoseBalance = isPetParentUser ? "your" : "the";
      return "We will automatically charge the payment method on file for ".concat(whoseBalance, " full outstanding balance ").concat(invoiceFrequencyClarification, " and send an itemized receipt.");
    }),
    secondaryAutoPayModalMessage: computed("clientHasAccountBalance", "isPetParentUser", "clientAccountBalance", "household.owner.last_name", function () {
      var clientHasAccountBalance = this.get("clientHasAccountBalance");
      var isPetParentUser = this.get("isPetParentUser");
      var clientAccountBalance = this.get("clientAccountBalance");
      var clientLastName = this.get("household.owner.last_name");
      var baseMessage = "AutoPay only runs when bills are scheduled to go out.";

      if (clientHasAccountBalance) {
        if (isPetParentUser) {
          return "".concat(baseMessage, " You currently have an outstanding balance of $").concat(clientAccountBalance, ". Would you like to pay this now?");
        }

        return "".concat(baseMessage, " The ").concat(clientLastName, " family's current outstanding balance of $").concat(clientAccountBalance, " will not be processed until their next bill closes. If you'd like for them to pay earlier, they will need to manually complete payment in their pet parent portal.");
      }

      return "";
    }),
    careProviderName: computed.alias('settings.company_name'),
    disabledToolTipText: computed('isPetParentUser', 'careProviderName', function () {
      var isPetParentUser = this.get('isPetParentUser');
      var careProviderName = this.get('careProviderName');

      if (isPetParentUser) {
        return "".concat(careProviderName, " requires AutoPay. This cannot be disabled.");
      } else {
        return 'This cannot be disabled because you require AutoPay for all clients.';
      }
    }),
    autoPaySettingsLink: computed('isPetParentUser', 'companyId', function () {
      var isPetParentUser = this.get('isPetParentUser');
      var companyId = this.get('companyId');
      return isPetParentUser ? '' : "/companies/".concat(companyId, "/billing/getting-paid");
    }),
    willDestroyElement: function willDestroyElement() {
      var percentageInputIsInvalid = this.get("showPercentageInputError");

      if (percentageInputIsInvalid) {
        this.send("updateAutoPayTipPercentage", null);
      }
    },
    updateTipPercentage: function updateTipPercentage(householdId, autoPayTipPercentage, data) {
      var _this = this;

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/update_auto_pay_tip_percentage"),
        method: "POST",
        data: data
      }).then(function () {
        _this.set("useAutoPayRecurringTip", !!autoPayTipPercentage);

        _this.set("settings.auto_pay_tip_percentage", autoPayTipPercentage);
      }).fail(function (response) {
        if (response.status == 422) {
          _this.get("flashMessage").error(response.responseJSON.error);
        }

        _this.set("settings.auto_pay_tip_percentage", null);

        _this.set("useAutoPayRecurringTip", false);
      });
    },
    actions: {
      validateTipValue: function validateTipValue() {
        var value = this.get("autoPayRecurringTipInputValue");
        var regex = /^(?:[5-9]|[1-9][0-9]|100)$/;
        var isValid = regex.test(value);
        this.set("showPercentageInputError", !isValid);
      },
      setUseAutoPayRecurringTip: function setUseAutoPayRecurringTip(useAutoPayRecurringTip) {
        if (useAutoPayRecurringTip) {
          this.send("updateAutoPayTipPercentage", 15);
        } else {
          this.send("updateAutoPayTipPercentage", null);
        }
      },
      setAutoPayTipPercentage: function setAutoPayTipPercentage(autoPayTipPercentage) {
        this.set("autoPayTipPercentage", autoPayTipPercentage);
      },
      closeAutoPayModal: function closeAutoPayModal() {
        this.set("showAutoPayModal", false);
      },
      updateAutoPay: function updateAutoPay(autoPay) {
        var _this2 = this;

        var householdId = this.get("household.id");
        var companyId = this.get("companyId");

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/update_autopay"),
          method: "POST",
          data: {
            auto_pay: autoPay,
            company_id: companyId
          }
        }).then(function () {
          _this2.set("settings.auto_pay", autoPay);

          if (autoPay) {
            _this2.set("showAutoPayModal", true);
          } else {
            _this2.send("updateAutoPayTipPercentage", null);
          }
        }).fail(function (response) {
          if (response.status == 422) {
            _this2.get("flashMessage").error(response.responseJSON.error);
          }
        });
      },
      updateAutoPayTipPercentage: function updateAutoPayTipPercentage(valueOrEvent) {
        var autoPayTipPercentage = valueOrEvent;

        if (valueOrEvent !== null && valueOrEvent !== void 0 && valueOrEvent.target) {
          autoPayTipPercentage = autoPayTipPercentage.target.value;
        }

        this.set("settings.auto_pay_tip_percentage", autoPayTipPercentage);
        var regex = /^(?:[5-9]|[1-9][0-9]|100)$/;
        var valueIsValid = regex.test(autoPayTipPercentage);

        if (valueIsValid || autoPayTipPercentage === null) {
          this.set("showPercentageInputError", false);
          var householdId = this.get("household.id");
          var companyId = this.get("companyId");
          var data = {
            company_id: companyId
          };

          if (autoPayTipPercentage) {
            data["autopay_percentage"] = parseFloat(autoPayTipPercentage);
          }

          Ember.run.debounce(this, this.updateTipPercentage, householdId, autoPayTipPercentage, data, 500);
        } else {
          this.set("showPercentageInputError", true);
        }
      }
    }
  });

  _exports.default = _default;
});