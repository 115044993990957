define("frontend/controllers/portal/invoices-summary/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    stripeInfo: inject.service(),
    infinity: inject.service(),
    media: inject.service(),
    router: inject.service(),
    flashMessage: inject.service(),
    reactApp: inject.service(),
    queryParams: ['showMakePaymentModal', 'showAddPaymentModal', 'directFromEmail', 'directToApptRequest'],
    showMakePaymentModal: false,
    showAddPaymentModal: false,
    directToApptRequest: false,
    parentCameFromEmailToMakePaymentButHasAlreadyDoneSo: false,
    displayName: computed.readOnly('model.company.displayName'),
    directFromEmail: false,
    currentRouteName: computed.readOnly('router.currentRouteName'),
    household: computed.readOnly('currentUser.user.household'),
    company_id: computed.readOnly('model.company.id'),
    settings: computed.readOnly('model.settings.settings'),
    invoiceDetail: inject.controller('portal.invoices-summary.company.invoice-detail'),
    paymentDetail: inject.controller('portal.invoices-summary.company.payment-detail'),
    activeInvoiceId: computed.readOnly('invoiceDetail.model.id'),
    activePaymentId: computed.readOnly('paymentDetail.model.id'),
    fetchedCustomerInfo: computed.readOnly('paymentMethods.isFulfilled'),
    onInvoiceDetailPage: computed.equal('currentRouteName', 'portal.invoices-summary.company.invoice-detail'),
    onPaymentDetailPage: computed.equal('currentRouteName', 'portal.invoices-summary.company.payment-detail'),
    companies: computed('settings', function () {
      var settings = this.get('settings');

      if (settings) {
        var settingsToShow = settings.filter(function (setting) {
          return !setting.denied_request && setting.pet_parents_activated || setting.staff_status == 'Owner';
        });
        return $.map(settingsToShow, function (companySettings) {
          return companySettings.company_name;
        });
      }

      return [];
    }),
    ownerUserId: computed('household', 'household.owner', function () {
      if (this.get('household.owner')) {
        return this.get('household.owner').get('id');
      }

      return null;
    }),
    paymentMethods: computed('ownerUserId', 'company_id', function () {
      var userId = this.get('ownerUserId');
      var companyId = this.get('company_id');

      if (userId && companyId) {
        var promise = this.get('stripeInfo').fetchCustomerInfo(userId, companyId);
        return _emberData.default.PromiseObject.create({
          promise: promise
        });
      }

      return null;
    }),
    selectedCompany: computed('settings', 'company_id', function () {
      var settings = this.get('settings');
      var company_id = this.get('company_id');

      if (settings && settings.length > 0) {
        return settings.find(function (s) {
          return s.company_id == company_id;
        });
      }

      return null;
    }),
    userClickedLinkFromFailedPaymentEmailObserver: observer('directFromEmail', 'showMakePaymentModal', 'selectedCompany.account_balance', function () {
      var directFromEmail = this.get('directFromEmail');
      var showMakePaymentModal = this.get('showMakePaymentModal');
      var accountBalance = this.get('selectedCompany.account_balance');
      var alreadyPaidUp = parseFloat(accountBalance) <= 0;

      if (directFromEmail && showMakePaymentModal) {
        if (alreadyPaidUp) {
          this.set('directFromEmail', false);
          this.set('showMakePaymentModal', false);
          this.set('showSuccessPaymentModal', true);
          this.set('parentCameFromEmailToMakePaymentButHasAlreadyDoneSo', true);
          this.transitionToRoute({
            queryParams: {
              showMakePaymentModal: false,
              directFromEmail: false
            }
          });
        } else {
          this.transitionToRoute({
            queryParams: {
              showMakePaymentModal: true,
              directFromEmail: false
            }
          });
        }
      }
    }),
    invoiceListItems: computed('selectedCompany', 'household', function () {
      var household_id = this.get('household.id');
      var company_id = this.get('selectedCompany.company_id');

      if (company_id && household_id) {
        return this.get('infinity').model('invoice-list-item', {
          company_id: company_id,
          perPage: 15,
          startingPage: 1
        });
      }

      return null;
    }),
    lastPayment: computed('invoiceListItems.[]', function () {
      var invoiceListItems = this.get('invoiceListItems');

      if (invoiceListItems && invoiceListItems.get('content')) {
        var plainArrayItems = invoiceListItems.get('content');
        var lastPaymentItem = plainArrayItems.find(function (invoiceListItem) {
          return invoiceListItem.isPayment;
        });
        return lastPaymentItem.get('invoice_payment');
      }

      return null;
    }),
    showMadePaymentModal: function showMadePaymentModal(balance, amountPaid, isTip) {
      this.set('showMakePaymentModal', false);
      this.set('showAddTipModal', false);
      this.set('selectedCompany.account_balance', balance);
      this.notifyPropertyChange('invoiceListItems');
      this.set('amountPaid', amountPaid);

      if (isTip) {
        this.set('showSuccessTipModal', true);
      } else {
        this.set('showSuccessPaymentModal', true);
      }
    },
    actions: {
      closeAddPaymentMethodModal: function closeAddPaymentMethodModal() {
        this.set('showAddPaymentModal', false); // When a parent tries to book an appt without having a card on file
        // and they choose to add a card from the alert, this param is added

        var directToApptRequest = this.get('directToApptRequest');

        if (directToApptRequest) {
          this.transitionToRoute('portal.appointments');
        }
      },
      changeCompany: function changeCompany(companyName) {
        var allSettings = this.get('settings');
        var settings = allSettings.find(function (s) {
          return s.company_name == companyName;
        });
        this.transitionToRoute('portal.invoices-summary.company', settings.company_id);
      },
      clickInvoice: function clickInvoice(invoice) {
        this.transitionToRoute('portal.invoices-summary.company.invoice-detail', this.get('company_id'), invoice.id);
      },
      clickPayment: function clickPayment(payment) {
        this.transitionToRoute('portal.invoices-summary.company.payment-detail', this.get('company_id'), payment.id);
      },
      clickMakeAPayment: function clickMakeAPayment() {
        var paymentMethods = this.get('paymentMethods.content');

        if (paymentMethods && paymentMethods.default) {
          this.set('showMakePaymentModal', true);
        } else {
          this.set('showAddPaymentModal', true);
          this.set('modalToShowAfterSave', 'showMakePaymentModal');
        }
      },
      clickAddTip: function clickAddTip(invoicePayment) {
        this.set('selectedPayment', invoicePayment);
        var paymentMethods = this.get('paymentMethods.content');

        if (paymentMethods && paymentMethods.default) {
          this.set('showAddTipModal', true);
        } else {
          this.set('showAddPaymentModal', true);
          this.set('modalToShowAfterSave', 'showAddTipModal');
        }
      },
      changePaymentMethod: function changePaymentMethod(modalToShowAfterSave) {
        this.set('showMakePaymentModal', false);
        this.set('showAddPaymentModal', true);
        this.set('modalToShowAfterSave', modalToShowAfterSave);
      },
      madePayment: function madePayment(balance, amountPaid) {
        var _this = this;

        var isTip = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        if (this.get('media.isMobile')) {
          this.transitionToRoute('portal.invoices-summary.company', this.get('company_id')).then(function () {
            _this.showMadePaymentModal(balance, amountPaid, isTip);
          });
        } else {
          this.showMadePaymentModal(balance, amountPaid, isTip);
        }
      },
      savePaymentMethod: function savePaymentMethod(token, paymentMethod) {
        var _this2 = this;

        this.set('showingPaymentMethodModal', false);
        this.set('updatingPaymentMethod', true);
        var userId = this.get('ownerUserId');
        this.get('stripeInfo').createPaymentMethod(userId, token, paymentMethod).then(function () {
          _this2.notifyPropertyChange('paymentMethods');

          _this2.set('showAddPaymentModal', false);

          _this2.set(_this2.get('modalToShowAfterSave'), true);

          _this2.set('updatingPaymentMethod', false);
        }).catch(function (result) {
          _this2.get('flashMessage').sendFlashMessage(result.payload.message, 'user-error');

          _this2.set('updatingPaymentMethod', false);
        }); // When a parent tries to book an appt without having a card on file
        // and they choose to add a card from the alert, this param is added

        var directToApptRequest = this.get('directToApptRequest');

        if (directToApptRequest) {
          this.transitionToRoute('portal.appointments');
        }
      },
      redirectToMicrodeposits: function redirectToMicrodeposits() {
        this.transitionToRoute('microdeposits', this.get('currentUser.user.emd5'));
      }
    }
  });

  _exports.default = _default;
});