define("frontend/components/calendar-list", ["exports", "frontend/config/environment", "frontend/utils/calendar-util", "moment", "frontend/services/flash-message", "jquery"], function (_exports, _environment, _calendarUtil, _moment, _flashMessage, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var computed = Ember.computed,
      inject = Ember.inject;

  function ignoreTouch(e) {
    e.preventDefault();
  }

  var _default = Ember.Component.extend({
    classNames: ['calendar-list'],
    scheduleDragActionService: inject.service(),
    careProviders: [],
    store: inject.service(),
    canScrollLeft: false,
    canScrollRight: false,
    checkDuplicateAppointmentTimes: inject.service(),
    checkStartedAppointment: inject.service(),
    timeZone: inject.service(),
    flashMessage: inject.service(),
    currentUser: inject.service(),
    canDrag: computed.readOnly('currentUser.user.canAddEditAppointments'),
    dragScrollActivated: false,
    onCancelationSchedule: false,
    highlightWindowStart: null,
    highlightWindowEnd: null,
    highlightWindowDate: null,
    showStartedAppointmentMessage: false,
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.showScrollArrows = this.showScrollArrows.bind(this);
      this.setupInteract = this.setupInteract.bind(this);
      this.teardownInteract = this.teardownInteract.bind(this);
      this.showScrollArrows();
      this.scrollToFirstService();

      _calendarUtil.default.initScrollArrows();

      _calendarUtil.default.initScrollHandler(this.showScrollArrows);

      _calendarUtil.default.initHeaders();

      this.updateCurrentTime();
      this.set('interval', window.setInterval(this.updateCurrentTime.bind(this), 30000));
      $(window).resize(function () {
        _calendarUtil.default.resizeExistingHeaders();

        _this2.showScrollArrows();
      });
      this.setupInteract();
    },
    setupInteract: function setupInteract() {
      var _this = this;

      var dragging = false;
      interact.dynamicDrop(true);
      interact('.draggable').on('click', function (event) {
        if (dragging) {
          (event.originalEvent || event).stopImmediatePropagation();
        }
      }, {
        capture: true
      }).draggable({
        inertia: false,
        onstart: function onstart(event) {
          if (_this.isDestroyed || _this.get('onCancelationSchedule')) {
            return;
          }

          var target = event.target;

          var _target$getBoundingCl = target.getBoundingClientRect(),
              x = _target$getBoundingCl.x,
              y = _target$getBoundingCl.y;

          var width = $(target).width() + 10;
          target.style.opacity = .5;
          var cloned = target.cloneNode(true);
          cloned.style.width = "".concat(width, "px");
          var draggableItem = document.querySelector('#draggable-item');
          draggableItem.style.width = "".concat(width, "px");
          draggableItem.style.position = 'fixed';
          draggableItem.style.display = 'inline-block';
          draggableItem.style.top = "".concat(y, "px");
          draggableItem.style.left = "".concat(x, "px");
          draggableItem.appendChild(cloned);
          document.querySelector('.calendar-body').classList.add('dragging');
          ['touchstart', 'touchmove', 'touchend'].forEach(function (et) {
            return document.querySelector('.calendar-body').addEventListener(et, ignoreTouch);
          });

          if (!target.classList.contains('no-drag-cancel')) {
            _this.set('scheduleDragActionService.showingCancel', true);
          }

          if (!target.classList.contains('no-drag-archive')) {
            _this.set('scheduleDragActionService.showingArchive', true);
          }

          var serviceId = target.getAttribute('data-service-id');

          if (serviceId) {
            var service = _this.get('store').peekAll('service').filterBy('id', serviceId).get('firstObject');

            var groupedAppts = service.get('sortedGroupedAppointmentsWithoutCanceled');
            var startTime = null;
            var endTime = null;
            groupedAppts.forEach(function (groupedAppt) {
              groupedAppt.get('sortedAppointments').forEach(function (appt) {
                var apptStartTime = appt.get('time_frame_start_time');
                var apptEndTime = appt.get('time_frame_end_time');

                if (apptStartTime && apptEndTime) {
                  if (startTime && endTime) {
                    if ((0, _moment.default)(apptStartTime, 'h:mmA').isAfter((0, _moment.default)(startTime, 'h:mmA'))) {
                      startTime = apptStartTime;
                    }

                    if ((0, _moment.default)(endTime, 'h:mmA').isAfter((0, _moment.default)(apptEndTime, 'h:mmA'))) {
                      endTime = apptEndTime;
                    }
                  } else {
                    startTime = apptStartTime;
                    endTime = apptEndTime;
                  }
                }
              });
            });

            if (startTime && endTime) {
              _this.set('highlightWindowStart', startTime);

              _this.set('highlightWindowEnd', endTime);
            }
          }

          var groupedAppointmentId = target.getAttribute('data-grouped-appointment-id');

          if (groupedAppointmentId) {
            var groupedAppointment = _this.get('store').peekAll('grouped-appointment').filterBy('id', groupedAppointmentId).get('firstObject');

            var firstAppt = groupedAppointment.get('appointments.firstObject');

            var _startTime = firstAppt.get('time_frame_start_time');

            var _endTime = firstAppt.get('time_frame_end_time');

            if (_startTime && _endTime) {
              _this.set('highlightWindowStart', _startTime);

              _this.set('highlightWindowEnd', _endTime);
            }
          }

          var appointmentRequestId = target.getAttribute('data-appointment-request-id');

          if (appointmentRequestId) {
            var appointmentRequest = _this.get('store').peekAll('appointment-request').filterBy('id', appointmentRequestId).get('firstObject');

            appointmentRequest.set('isDragging', true);

            var _startTime2 = appointmentRequest.get('time_frame_start_time');

            var _endTime2 = appointmentRequest.get('time_frame_end_time');

            if (_startTime2 && _endTime2) {
              _this.set('highlightWindowStart', _startTime2);

              _this.set('highlightWindowEnd', _endTime2);

              _this.set('highlightWindowDate', appointmentRequest.get('starts_at_date'));
            }
          }

          _this.set('dragScrollActivated', true);

          dragging = true;
        },
        onend: function onend(event) {
          if (_this.isDestroyed || _this.get('onCancelationSchedule')) {
            return;
          }

          var target = event.target;
          target.style.display = 'inline-block';
          target.style.opacity = 1;
          target.style.backgroundColor = '';
          var draggableItem = document.querySelector('#draggable-item');
          draggableItem.removeChild(draggableItem.firstChild);
          draggableItem.setAttribute('data-x', '');
          draggableItem.setAttribute('data-y', '');
          document.querySelector('.calendar-body').classList.remove('dragging');

          _this.get('scheduleDragActionService').stopDragging();

          _this.set('dragScrollActivated', false);

          _this.set('highlightWindowStart', null);

          _this.set('highlightWindowEnd', null);

          var appointmentRequestId = target.getAttribute('data-appointment-request-id');

          if (appointmentRequestId) {
            var appointmentRequest = _this.get('store').peekAll('appointment-request').filterBy('id', appointmentRequestId).get('firstObject');

            appointmentRequest.set('isDragging', false);
          }

          ['touchstart', 'touchmove', 'touchend'].forEach(function (et) {
            return document.querySelector('.calendar-body').removeEventListener(et, ignoreTouch);
          }); // This needs to run after the click event fires so that we can properly disable clicking when dragging

          Ember.run.scheduleOnce('afterRender', function () {
            return dragging = false;
          });
        },
        onmove: function onmove(event) {
          if (_this.isDestroyed || _this.get('onCancelationSchedule')) {
            return;
          }

          var draggableItem = document.querySelector('#draggable-item');
          var x = (parseFloat(draggableItem.getAttribute('data-x')) || 0) + event.dx;
          var y = (parseFloat(draggableItem.getAttribute('data-y')) || 0) + event.dy;
          draggableItem.style.webkitTransform = draggableItem.style.transform = "translate(".concat(x, "px, ").concat(y, "px)");
          draggableItem.setAttribute('data-x', x);
          draggableItem.setAttribute('data-y', y);
        }
      });
      interact('.drop-zone').dropzone({
        accept: '.draggable',
        ondragenter: function ondragenter(event) {
          if (_this.isDestroyed || _this.get('onCancelationSchedule')) {
            return;
          }

          var dropzoneElement = event.target;
          var height = "".concat(event.relatedTarget.getAttribute('data-height'), "px");
          var allDayService = event.relatedTarget.getAttribute('data-all-day-service') === 'true';

          if (allDayService) {
            $(dropzoneElement).parent().parent().find('.all-day-services').addClass('accepts-drag');
          } else {
            $(dropzoneElement).find('.drag-box').css({
              height: height
            });
            dropzoneElement.classList.add('accepts-drag');
          }
        },
        ondragleave: function ondragleave(event) {
          if (_this.isDestroyed || _this.get('onCancelationSchedule')) {
            return;
          }

          var dropzoneElement = event.target;
          var allDayService = event.relatedTarget.getAttribute('data-all-day-service') === 'true';

          if (allDayService) {
            $(dropzoneElement).parent().parent().find('.all-day-services').removeClass('accepts-drag');
          } else {
            dropzoneElement.classList.remove('accepts-drag');
          }
        },
        ondrop: function ondrop(event) {
          if (_this.isDestroyed || _this.get('onCancelationSchedule')) {
            return;
          }

          var dropzoneElement = event.target;
          dropzoneElement.classList.remove('accepts-drag');
          $(dropzoneElement).parent().parent().find('.all-day-services').removeClass('accepts-drag');
          var draggableElement = event.relatedTarget;
          var date = dropzoneElement.getAttribute('data-date');
          var minute = dropzoneElement.getAttribute('data-minute');
          var hour = dropzoneElement.getAttribute('data-hour');

          var companyOffset = _this.get('timeZone').offsetDSTAware((0, _moment.default)(date).format('YYYY-MM-DD'));

          var momentTime = (0, _moment.default)(date, 'YYYY-MM-DD').utcOffset(companyOffset, true).hour(hour).minute(minute);
          var starts_at = momentTime.format();
          var care_provider_id = dropzoneElement.getAttribute('data-care-provider-id');
          var serviceId = draggableElement.getAttribute('data-service-id');
          var groupedAppointmentId = draggableElement.getAttribute('data-grouped-appointment-id');
          var appointmentRequestId = draggableElement.getAttribute('data-appointment-request-id');

          if (serviceId) {
            _this.moveService(serviceId, starts_at, care_provider_id);
          } else if (groupedAppointmentId) {
            _this.moveGroupedAppointment(groupedAppointmentId, starts_at, care_provider_id);
          } else {
            _this.approveAppointmentRequest(appointmentRequestId, starts_at, care_provider_id);
          }
        }
      });
    },
    moveService: function moveService(serviceId, starts_at, care_provider_id) {
      var _this3 = this;

      var no = function no() {
        return new Ember.RSVP.Promise(function (resolve) {
          return resolve();
        });
      };

      var yes = function yes() {
        return _this3.handleMovingService(serviceId, starts_at, care_provider_id);
      };

      var service = this.get('store').peekAll('service').filterBy('id', serviceId).get('firstObject');
      var hasAppointments = [];
      var apptsOutsideOfTimeWindow = [];
      var startedAppointments = [];
      var startsAtString = (0, _moment.default)(starts_at).format('h:mm A');
      service.get('appointments').forEach(function (appt) {
        if (appt.get('started_at') && !appt.get('finished_at')) {
          startedAppointments.push(appt);
        }
      }); // Commented out this code until we add the dragged out of time window modal back
      // let startTime = moment(startsAtString, 'h:mm A');
      // service.get('appointments').forEach((appointment) => {
      //   if (this.get('checkDuplicateAppointmentTimes').isDuplicate(appointment, starts_at)) {
      //     hasAppointments.pushObject(appointment.get('pet.name'));
      //   }
      //
      //   let timeFrameStart = moment(appointment.get('time_frame_start_time'), 'h:mmA');
      //   let timeFrameEnd = moment(appointment.get('time_frame_end_time'), 'h:mmA');
      //
      //   if (startTime.isBefore(timeFrameStart) || startTime.isAfter(timeFrameEnd)) {
      //     apptsOutsideOfTimeWindow.push(appointment);
      //   }
      // });

      if (apptsOutsideOfTimeWindow.length > 0) {
        this.set('serviceBeingMoved', service);
        this.set('serviceAppointmentsOutOfWindow', apptsOutsideOfTimeWindow);
        this.set('careProviderId', care_provider_id);
        this.set('startsAt', starts_at);
        this.set('selectedStartTime', startsAtString);
        this.set('showOutOfTimeWindowModal', true);
      } else if (hasAppointments.length > 0) {
        var message = "".concat(hasAppointments.join(', '), " already ").concat(hasAppointments.length > 1 ? 'have an appointment' : 'has an appointment', " at that time");
        this.get('flashMessage').confirm(yes, no, message, 'Proceed', 'Cancel');
      } else if (startedAppointments.length >= 1) {
        if (this.get('currentUser.user').shouldSeeMessage('drag-drop-started-appointment')) {
          this.set('showStartedAppointmentMessage', true);
        }

        no();
      } else {
        yes();
      }
    },
    moveGroupedAppointment: function moveGroupedAppointment(groupedAppointmentId, starts_at, care_provider_id) {
      var _this4 = this;

      var no = function no() {
        return new Ember.RSVP.Promise(function (resolve) {
          return resolve();
        });
      };

      var yes = function yes() {
        return _this4.handleMovingAppointment(groupedAppointmentId, starts_at, care_provider_id);
      };

      var groupedAppointment = this.get('store').peekAll('grouped-appointment').filterBy('id', groupedAppointmentId).get('firstObject'); // Commented out this code until we add the dragged out of time window modal back
      // let startsAtString = moment(starts_at).format('h:mm A');
      // let startTime = moment(startsAtString, 'h:mm A');
      // let firstAppointment = groupedAppointment.get('appointments.firstObject');
      // let timeFrameStart = moment(firstAppointment.get('time_frame_start_time'), 'h:mmA');
      // let timeFrameEnd = moment(firstAppointment.get('time_frame_end_time'), 'h:mmA');
      //
      // if (startTime.isBefore(timeFrameStart) || startTime.isAfter(timeFrameEnd)) {
      //   this.set('groupedAppointmentBeingMoved', groupedAppointment);
      //   this.set('careProviderId', care_provider_id);
      //   this.set('startsAt', starts_at);
      //   this.set('selectedStartTime', startsAtString);
      //   this.set('showOutOfTimeWindowModal', true);
      //   return;
      // }

      var isDuplicate = this.get('checkDuplicateAppointmentTimes').isGroupedAppointmentDuplicate(groupedAppointment, starts_at);

      if (isDuplicate) {
        var message = "".concat(groupedAppointment.get('petNames'), " already has an appointment at that time");
        this.get('flashMessage').confirm(yes, no, message, 'Proceed', 'Cancel');
      } else if (this.get('checkStartedAppointment').hasStartedAppointment(groupedAppointment)) {
        if (this.get('currentUser.user').shouldSeeMessage('drag-drop-started-appointment')) {
          this.set('showStartedAppointmentMessage', true);
        }

        no();
      } else {
        yes();
      }
    },
    approveAppointmentRequest: function approveAppointmentRequest(appointmentRequestId, starts_at, care_provider_id) {
      var appointmentRequest = this.get('store').peekAll('appointment-request').filterBy('id', appointmentRequestId).get('firstObject');

      var startsAtString = _moment.default.parseZone(starts_at).format('h:mm A');

      var startTime = _moment.default.parseZone(startsAtString, 'h:mm A');

      var timeFrameStart = _moment.default.parseZone(appointmentRequest.get('time_frame_start_time'), 'h:mmA');

      var timeFrameEnd = _moment.default.parseZone(appointmentRequest.get('time_frame_end_time'), 'h:mmA');

      var serviceDate = _moment.default.parseZone(starts_at).startOf('day');

      var startsAtDate = _moment.default.parseZone(appointmentRequest.get('starts_at')).startOf('day');

      if (startTime.isBefore(timeFrameStart) || startTime.isAfter(timeFrameEnd) || !serviceDate.isSame(startsAtDate)) {
        this.set('appointmentRequestBeingConfirmed', appointmentRequest);
        this.set('careProviderId', care_provider_id);
        this.set('startsAt', starts_at);
        this.set('selectedStartTime', startsAtString);

        if (!serviceDate.isSame(startsAtDate)) {
          this.set('movedToWrongDate', true);
        }

        this.set('showOutOfTimeWindowModal', true);
        return;
      }

      this.handleApprovingRequest(appointmentRequest, starts_at, care_provider_id);
    },
    handleMovingService: function handleMovingService(serviceId, starts_at, care_provider_id) {
      var _this5 = this;

      return _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/services/").concat(serviceId),
        method: 'PUT',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          care_provider_id: care_provider_id,
          starts_at: starts_at
        })
      }).done(function (response) {
        _this5.get('store').pushPayload(response);

        _calendarUtil.default.resizeExistingHeaders(); // This is for properly resizing & removing the existing time box


        _this5.notifyPropertyChange('timeBoxes');

        _this5.get('servicesChanged')();
      }).fail(function (failResponse) {
        _this5.notifyUserOfError(failResponse);
      });
    },
    handleMovingAppointment: function handleMovingAppointment(groupedAppointmentId, starts_at, care_provider_id) {
      var _this6 = this;

      return _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/grouped_appointments/").concat(groupedAppointmentId, "/move"),
        method: 'PUT',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          position: 0,
          care_provider_id: care_provider_id,
          starts_at: starts_at
        })
      }).done(function (response) {
        _this6.get('store').pushPayload(response);

        _calendarUtil.default.resizeExistingHeaders(); // This is for properly resizing & removing the existing time box


        _this6.notifyPropertyChange('timeBoxes');

        _this6.get('servicesChanged')();
      }).fail(function (failResponse) {
        _this6.notifyUserOfError(failResponse);
      });
    },
    handleApprovingRequest: function handleApprovingRequest(appointmentRequest, starts_at, care_provider_id) {
      var _this7 = this;

      if (this.get('currentUser.user').shouldSeeMessage('drag-drop-appointment-request')) {
        this.set('showDragDropRequestMessage', true);
      }

      var offered_service_id = appointmentRequest.get('offered_service.id');
      var time_frame_start_time = appointmentRequest.get('time_frame_start_time');
      var time_frame_end_time = appointmentRequest.get('time_frame_end_time');
      var date_time = starts_at;
      var special_instructions = appointmentRequest.get('special_instructions');

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests/").concat(appointmentRequest.get('id'), "/approve"),
        method: 'POST',
        data: {
          care_provider_id: care_provider_id,
          offered_service_id: offered_service_id,
          time_frame_start_time: time_frame_start_time,
          time_frame_end_time: time_frame_end_time,
          date_time: date_time,
          special_instructions: special_instructions
        }
      }).then(function (response) {
        _this7.get('store').pushPayload(response);

        _calendarUtil.default.resizeExistingHeaders(); // This is for properly resizing & removing the existing time box


        _this7.notifyPropertyChange('timeBoxes');

        appointmentRequest.deleteRecord();

        _this7.get('servicesChanged')();
      });
    },
    clearAppointmentOrServiceBeingMoved: function clearAppointmentOrServiceBeingMoved() {
      this.set('groupedAppointmentBeingMoved', null);
      this.set('appointmentRequestBeingConfirmed', null);
      this.set('serviceBeingMoved', null);
      this.set('serviceAppointmentsOutOfWindow', null);
      this.set('careProviderId', null);
      this.set('selectedStartTime', null);
      this.set('startsAt', null);
      this.set('movedToWrongDate', false);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      _calendarUtil.default.initHeaders();

      _calendarUtil.default.resizeExistingHeaders();
    },
    updateCurrentTime: function updateCurrentTime() {
      this.set('currentTime', (0, _moment.default)());
      var midnight = this.get('currentTime').clone().startOf('day');
      this.set('seconds', this.get('currentTime').diff(midnight, 'seconds') / 28.125 + 122);
    },
    currentTimeStyle: computed('seconds', function () {
      return Ember.String.htmlSafe("top: ".concat(this.seconds, "px;"));
    }),
    showScrollArrows: function showScrollArrows() {
      if (!this || !(0, _jquery.default)(this.element.querySelectorAll('.calendar-body'))) {
        return;
      }

      var _jQuery = (0, _jquery.default)(this.element.querySelectorAll('.calendar-body')),
          _jQuery2 = _slicedToArray(_jQuery, 1),
          calendarBody = _jQuery2[0];

      var amountToScroll = calendarBody.scrollWidth - calendarBody.offsetWidth;

      if (amountToScroll > 0) {
        this.set('canScrollRight', amountToScroll > calendarBody.scrollLeft);
        this.set('canScrollLeft', calendarBody.scrollLeft > 0);
      } else {
        this.set('canScrollRight', false);
        this.set('canScrollLeft', false);
      }
    },
    nowAtHourMinute: function nowAtHourMinute(time) {
      var momentTime = _moment.default.parseZone(time);

      return (0, _moment.default)().hour(momentTime.hour()).minute(momentTime.minute());
    },
    scrollToFirstService: function scrollToFirstService() {
      var _this8 = this;

      if ((0, _jquery.default)(this.element.querySelectorAll('.calendar-body'))[0].scrollTop !== 0) {
        return;
      }

      var firstService = this.get('services.firstObject');

      if (firstService) {
        this.get('services').forEach(function (service) {
          var serviceMoment = _this8.nowAtHourMinute(service.get('starts_at'));

          var firstServiceMoment = _this8.nowAtHourMinute(firstService.get('starts_at'));

          if (serviceMoment.isBefore(firstServiceMoment) && service.get('appointments.length') > 0) {
            firstService = service;
          }
        });
        var $service = (0, _jquery.default)(".service-box[data-service-id=".concat(firstService.get('id'), "]"));

        if ($service.length > 0) {
          (0, _jquery.default)(this.element.querySelectorAll('.calendar-body'))[0].scrollTop = $service.parent()[0].offsetTop - 120;
        } else {
          (0, _jquery.default)(this.element.querySelectorAll('.calendar-body'))[0].scrollTop = 900;
        }
      } else {
        (0, _jquery.default)(this.element.querySelectorAll('.calendar-body'))[0].scrollTop = 900;
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      _calendarUtil.default.teardownTimeScrolling();

      _calendarUtil.default.teardownScrollArrows();

      $(window).off('resize');
      window.clearInterval(this.get('interval'));
      this.teardownInteract();
    },
    teardownInteract: function teardownInteract() {
      interact('.draggable').unset();
      interact('.drop-zone').unset();
    },
    timeBoxes: computed('services.@each.starts_at', 'date', function () {
      // IMPORTANT this is a non DST switch day so hours don't repeat
      var FIXED_DAY = '2017-01-01';
      var firstTime = (0, _moment.default)(FIXED_DAY).startOf('day');
      var lastTime = (0, _moment.default)(FIXED_DAY).endOf('day');
      var times = [];
      var serviceTimes = {
        'allDay': {
          0: []
        }
      };
      this.get('services').forEach(function (service) {
        if (service.get('all_day_service')) {
          serviceTimes.allDay[0].push(service);
        } else {
          var serviceMoment = _moment.default.parseZone(service.get('starts_at'));

          var minutes = serviceMoment.get('minute');
          var hour = serviceMoment.get('hour');

          if (!serviceTimes[hour]) {
            serviceTimes[hour] = {};
          }

          if (minutes < 8) {
            if (!serviceTimes[hour][0]) {
              serviceTimes[hour][0] = [];
            }

            serviceTimes[hour][0].push(service);
          }

          if (minutes < 23 && minutes >= 8) {
            if (!serviceTimes[hour][1]) {
              serviceTimes[hour][1] = [];
            }

            serviceTimes[hour][1].push(service);
          }

          if (minutes < 38 && minutes >= 23) {
            if (!serviceTimes[hour][2]) {
              serviceTimes[hour][2] = [];
            }

            serviceTimes[hour][2].push(service);
          }

          if (minutes < 60 && minutes >= 38) {
            if (!serviceTimes[hour][3]) {
              serviceTimes[hour][3] = [];
            }

            serviceTimes[hour][3].push(service);
          }
        }
      });
      times.push({
        time: 'all-day',
        faded: false,
        hidden: false,
        services: serviceTimes.allDay[0],
        services1: [],
        services2: [],
        services3: []
      });

      while (firstTime < lastTime) {
        var servicesAtHour = serviceTimes[firstTime.get('hour')] || [];
        var faded = false;
        var hidden = false;
        var time = firstTime.format('h A');
        var hour = +firstTime.clone().format('H');

        if (hour < 6) {
          faded = true;
        }

        if (hour === 0) {
          hidden = true;
        }

        times.push({
          time: time,
          faded: faded,
          hidden: hidden,
          hour: hour,
          services: servicesAtHour[0] || [],
          services1: servicesAtHour[1] || [],
          services2: servicesAtHour[2] || [],
          services3: servicesAtHour[3] || []
        });
        firstTime.add(1, 'hour');
      }

      return times;
    }),
    notifyUserOfError: function notifyUserOfError(failResponse) {
      if (failResponse && failResponse.responseText) {
        var parsedResponse = JSON.parse(failResponse.responseText);

        if (parsedResponse.errors) {
          this.get('flashMessage').errorWithRefresh(parsedResponse.errors.service);
        } else if (parsedResponse.message) {
          this.get('flashMessage').errorWithRefresh(parsedResponse.message);
        } else {
          this.get('flashMessage').errorWithRefresh(_flashMessage.UNEXPECTED_ERROR_MESSAGE);
        }
      } else {
        this.get('flashMessage').errorWithRefresh(_flashMessage.UNEXPECTED_ERROR_MESSAGE);
      }
    },
    actions: {
      createNewAppointment: function createNewAppointment(careProvider, e) {
        var target = e.target;
        var date = target.getAttribute('data-date');
        var minute = target.getAttribute('data-minute');
        var hour = target.getAttribute('data-hour');

        if (target.classList.contains('time-target')) {
          var dateTime = (0, _moment.default)(date).hour(hour).minute(minute);
          this.get('createNewAppointment')(careProvider, dateTime);
        }
      },
      clickAppointment: function clickAppointment(appointment) {
        if (this.get('clickAppointment')) {
          this.get('clickAppointment')(appointment);
        }
      },
      denyUpdateTimeWindow: function denyUpdateTimeWindow() {
        this.set('showOutOfTimeWindowModal', false);
        this.clearAppointmentOrServiceBeingMoved();
      },
      confirmUpdateTimeWindow: function confirmUpdateTimeWindow() {
        if (this.get('groupedAppointmentBeingMoved')) {
          this.handleMovingAppointment(this.get('groupedAppointmentBeingMoved.id'), this.get('startsAt'), this.get('careProviderId'));
        } else if (this.get('appointmentRequestBeingConfirmed')) {
          this.handleApprovingRequest(this.get('appointmentRequestBeingConfirmed'), this.get('startsAt'), this.get('careProviderId'));
        } else {
          this.handleMovingService(this.get('serviceBeingMoved.id'), this.get('startsAt'), this.get('careProviderId'));
        }

        this.set('showOutOfTimeWindowModal', false);
        this.clearAppointmentOrServiceBeingMoved();
      },
      hideDragDropRequestMessage: function hideDragDropRequestMessage() {
        this.set('showDragDropRequestMessage', false);
      },
      dismissedDragDropRequestMessage: function dismissedDragDropRequestMessage() {
        this.send('hideDragDropRequestMessage');
        this.get('currentUser.user').reload();
      },
      hideStartedAppointmentMessage: function hideStartedAppointmentMessage() {
        this.set('showStartedAppointmentMessage', false);
      },
      dismissedStartedAppointmentMessage: function dismissedStartedAppointmentMessage() {
        this.send('hideStartedAppointmentMessage');
        this.get('currentUser.user').reload();
      }
    }
  });

  _exports.default = _default;
});