define("frontend/templates/components/holiday-update-reminder-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jld2X1tF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"epayments-reminder-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"header-image\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"assets/images/modal-icons/icon-online-payments-requirement.svg\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n    Review your holiday policies!\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    Let’s get ready for 2025! It’s time to review your holiday surcharges so that we’re applying your fees to appointments on the appropriate days in the new year.\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"control-panel\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"color\",\"onclick\",\"class\"],[\"red\",[28,\"action\",[[23,0,[]],\"goToPolicies\"],null],\"setup-button\"]],{\"statements\":[[0,\"      Let's go!\\n\"]],\"parameters\":[]},null],[4,\"ppb-button\",null,[[\"color\",\"onclick\",\"class\"],[\"red-white\",[28,\"action\",[[23,0,[]],\"onClose\"],null],\"dismiss-button\"]],{\"statements\":[[0,\"      remind me later\\n\"]],\"parameters\":[]},null],[4,\"ppb-button\",null,[[\"color\",\"onclick\",\"class\"],[\"red-white\",[28,\"action\",[[23,0,[]],\"permenantlyDismissModal\"],null],\"dismiss-button\"]],{\"statements\":[[0,\"      I’ve completed this task!\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/holiday-update-reminder-modal.hbs"
    }
  });

  _exports.default = _default;
});