define("frontend/components/holiday-surcharge-policy-modal", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    store: inject.service(),
    serviceCategory: null,
    holidaySurchargePolicy: null,
    company: null,
    percentageType: computed.equal('holidaySurchargePolicy.amount_type', 'percentage'),
    fixedType: computed.equal('holidaySurchargePolicy.amount_type', 'fixed'),
    chargePerDay: computed.readOnly('holidaySurchargePolicy.charge_per_day'),
    chargePerVisit: computed.not('chargePerDay'),
    percentageCompensation: computed.equal('holidaySurchargePolicy.employee_compensation_type', 'percentage'),
    fixedCompensation: computed.equal('holidaySurchargePolicy.employee_compensation_type', 'fixed'),
    availableHolidayDates: null,
    surchargeTypeOptions: [{
      label: 'fixed $ per visit',
      value: 'fixed'
    }, {
      label: '% of service cost',
      value: 'percentage'
    }],
    didInsertElement: function didInsertElement() {
      this.set('loading', false);

      if (this.get('holidaySurchargePolicy')) {
        this.set('originalDates', _toConsumableArray(this.get('holidaySurchargePolicy.dates')));
      }

      if (!this.get('newHolidaySurchargePolicy') && this.get('fixedType')) {
        var fee = this.get('holidaySurchargePolicy.amount');
        this.set('holidaySurchargePolicy.amount', this.formatMoney(fee, ''));
      }
    },
    amountTypeObserver: observer('holidaySurchargePolicy.amount_type', function () {
      if (this.get('holidaySurchargePolicy.amount_type') === 'percentage') {
        this.set('holidaySurchargePolicy.charge_per_day', false);
      }
    }),
    amountError: computed('percentageType', 'holidaySurchargePolicy.amount', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        var amount = this.get('holidaySurchargePolicy.amount');

        if (!amount) {
          return '* required';
        }

        if (amount <= 0) {
          return 'must be > 0';
        }

        if (amount > 100 && this.get('percentageType')) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    compensationAmountError: computed('percentageCompensation', 'holidaySurchargePolicy.employee_compensation_amount', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        var amount = this.get('holidaySurchargePolicy.employee_compensation_amount');

        if (!amount && amount != 0 || amount === '') {
          return '* required';
        }

        if (amount < 0) {
          return 'must be >= 0';
        }

        if (amount > 100 && this.get('percentageCompensation')) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    actions: {
      toggleChargePerDay: function toggleChargePerDay() {
        this.toggleProperty('holidaySurchargePolicy.charge_per_day');
      },
      cancel: function cancel() {
        if (this.get('loading')) {
          return;
        }

        this.get('onClose')();
        this.get('holidaySurchargePolicy').rollbackAttributes();

        if (this.get('holidaySurchargePolicy.id')) {
          this.set('holidaySurchargePolicy.dates', this.get('originalDates'));
        }
      },
      changeAmountType: function changeAmountType(option) {
        this.set('holidaySurchargePolicy.amount_type', option.value);
      },
      save: function save() {
        var _this = this;

        this.set('triedToSave', true);

        if (this.get('amountError') || this.get('compensationAmountError') || this.get('holidaySurchargePolicy.dates.length') == 0) {
          return;
        }

        this.set('loading', true);

        if (this.get('newHolidaySurchargePolicy')) {
          var policy = this.get('holidaySurchargePolicy');
          Ember.$.ajax({
            url: '/api/v2/holiday_surcharge_policies',
            method: 'POST',
            data: {
              holiday_surcharge_policy: {
                service_category_id: this.get('serviceCategory.id'),
                amount: policy.get('amount'),
                amount_type: policy.get('amount_type'),
                dates: policy.get('dates'),
                employee_compensation_type: policy.get('employee_compensation_type'),
                employee_compensation_amount: policy.get('employee_compensation_amount'),
                charge_per_day: policy.get('charge_per_day'),
                year: policy.get('year')
              }
            }
          }).then(function (response) {
            _this.get('holidaySurchargePolicy').deleteRecord();

            _this.get('serviceCategory').reload().then(function (category) {
              _this.get('onClose')();
            });
          });
        } else {
          // Updates will destroy the record and create a new one which Ember data
          // doesn't like
          var _policy = this.get('holidaySurchargePolicy');

          var companyServiceCategoryId = _policy.id.split('-')[0];

          Ember.$.ajax({
            url: "/api/v2/holiday_surcharge_policies/".concat(companyServiceCategoryId),
            method: 'PUT',
            data: {
              holiday_surcharge_policy: {
                service_category_id: this.get('serviceCategory.id'),
                amount: _policy.get('amount'),
                amount_type: _policy.get('amount_type'),
                dates: _policy.get('dates'),
                employee_compensation_type: _policy.get('employee_compensation_type'),
                employee_compensation_amount: _policy.get('employee_compensation_amount'),
                charge_per_day: _policy.get('charge_per_day'),
                year: _policy.get('year')
              }
            }
          }).then(function (response) {
            _this.get('onClose')();
          });
        }
      }
    }
  });

  _exports.default = _default;
});